import React, {useEffect, useState} from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";

import {
  AppBar,
  Avatar,
  Toolbar,
  Button,
  Switch as SwitchUI,
  Container,
  Link as LinkUI,
  Snackbar, Box,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { connectWallet, useWallet } from "./wallet";

import cookie from 'react-cookies'

import Home from "./pages/home";
import Farms from "./pages/farms";
import Mining from "./pages/mining";
import SingleMining from "./pages/singleMining";
import Rules from "./pages/rules";
import Inviter from "./pages/inviter";
import Stats from "./pages/stats";
import Bonus from "./pages/bonus";
import Activity from "./pages/activity";
// import ActivityList from "./pages/activityList";

import logo from './assets/logo.png';
import axios from "axios";
import {inspect} from "util";
// import colors = module


const l10n: { [key: string]: { [key: string]: string } } = {
  'EN': {
    title: 'WAS',
    wallet: 'Wallet',
    home: 'Home',
    farm: 'Mining',
    stats: 'Mint',
    rules: 'Lighting Interest',
    bonus: 'Notice',
    contact: 'Contact Us',
    noMetaMaskTips: 'Please install MetaMask.',
    activity: 'Activity',
    txSent:"You have a new notification"
  },
  '中文': {
    title: '芥末',
    wallet: '钱包',
    home: '首页',
    farm: '挖矿',
    stats: '铸造',
    rules: '闪电利',
    bonus: '公告',
    contact: '联系我们',
    noMetaMaskTips: '请先安装 MetaMask.',
    activity: '活动',
    txSent:"你有新的通知"
  }
};

const useStyles = makeStyles({
  appBar: { boxShadow: 'none' },
  link: {
    textDecoration: 'none',
    color: '#4c3444',
    marginLeft: '12px',
    flexGrow: 1,
  },
  linkContainer: {
    textAlign: "center",
    width:'100%',
    marginTop: 4,

    textAlignLast: "center",
    marginBottom: 20,
  },
  link2: {
    textDecoration: 'none',
    color: '#737276',

    fontWeight: 'bold',
    padding: '8px',
  },
  link2_2: {
    textDecoration: 'none',
    color: '#5cca39',
    fontWeight: 'bold',
    padding: '8px',
  },
  linkSelected: {
    color: '#09E2D5'
  },
  title: {
    fontWeight: 'bold',
  },
  bottomLink: {
    padding: '8px'
  }
});

function useLang(): [string, (v: string) => void] {
  const lang = localStorage.getItem('lang') || 'EN';
  const [l, setL] = useState(lang);

  const wrapSetL = (lang: string) => {
    setL(lang);
    localStorage.setItem('lang', lang);
  };

  return [l, wrapSetL];
}

var flag = true;
var bb = true;
let i = 0
let setIntervalTask: any = undefined;
let db = {
  hasMessage: false
};
const App = () => {
  const styles = useStyles();
  const [lang, setLang] = useLang();
  const [snackbar, setSnackbar] = useState(false);
  const [snackbar1, setSnackbar1] = useState(false);
  const [account, contracts,myProviders] = useWallet();
  // let [newMessage, setNewMessage] = useState('');
  let [data, setData] = useState(db);


  function notice(){
      axios.get("https://notice.wcmd.pro:442/publicApi/getAllNotification")
          .then((response: any) => {
            if (response.data.status == 0) {
              if (response.data.data.length > 0) {
                const len = response.data.data.length;
                for (var i = 0; i < len; i++) {
                  if (cookie.load("notice-" + response.data.data[i].id) == null) {
                    if (flag) {
                      flag = false;
                      setSnackbar1(true)
                    }
                    db.hasMessage = true;
                    setData(JSON.parse(JSON.stringify(db)))
                    return;
                  }
                }
                db.hasMessage = false;
                setData(JSON.parse(JSON.stringify(db)))
              }
            }
          })
    // }
  }

  //
  useEffect(() => {
    setIntervalTask  = setInterval(()=>{
      notice();
    },1000)
  }, []);
  return (
    <Router>
      <AppBar position="static" color='transparent' className={styles.appBar}>
        <Toolbar>
          <Link to="/" style={{ flexGrow: 1 }}>
            <img src={logo} style={{ height: 20 }} />
          </Link>

          {/* <Link to="/" className={styles.link}>
            <Typography variant="h6" className={styles.title}>{l10n[lang].title}</Typography>
          </Link> */}

          {
            account
              ?
              <Button
                size="small"
                variant="text"
                style={{color:"#ffffff"}}
              >{`${account.substring(0, 6)}...${account.substring(38)}`}</Button>
              :
              <Button
                size="small"
                variant="outlined"
                style={{color:"#ffffff"}}
                onClick={() => connectWallet(() => setSnackbar(true))}
              >{l10n[lang].wallet}</Button>
          }
          <SwitchUI
            checked={lang === '中文'}
            color="primary"
            onChange={(event: any) => setLang(event.target.checked ? '中文' : 'EN')}
            name="checkedA"
          />
          <span style={{color:"#ffffff"}}>{lang}</span>
        </Toolbar>
      </AppBar>


      <Container className={styles.linkContainer}>
        {/*{{newMessage}}*/}
        <NavLink to="/" exact className={styles.link2} activeClassName={styles.linkSelected}>{l10n[lang].home}</NavLink>
        <NavLink to="/mining" className={styles.link2} activeClassName={styles.linkSelected}>{l10n[lang].farm}</NavLink>
        <NavLink to="/activity" className={styles.link2} activeClassName={styles.linkSelected}>{l10n[lang].activity}</NavLink>
        <NavLink to="/stats" className={styles.link2} activeClassName={styles.linkSelected}>{l10n[lang].stats}</NavLink>
        <NavLink to="/rules" className={styles.link2} activeClassName={styles.linkSelected}>{l10n[lang].rules}</NavLink>
        <NavLink to="/bonus" className={data.hasMessage?styles.link2_2:styles.link2} activeClassName={styles.linkSelected}>{l10n[lang].bonus}</NavLink>

        {/*<a href="https://medium.com/@wasabiblockchain" target="_blank" className={styles.link2} rel="noreferrer"> 7-{data.hasMessage}</a>*/}

      </Container>

      <Container style={{ minHeight: 'calc(100vh - 240px)' }}>
        <Switch>
          <Route exact path="/">
            <Home lang={lang} account={account} contracts={contracts} />
          </Route>
          {/* <Route path="/farms/:farm">
            <Farm lang={lang} account={account} contracts={contracts} />
          </Route> */}

          <Route path="/mining/single/:SingleMining">
            <SingleMining lang={lang} account={account} contracts={contracts}  myProviders = {myProviders}/>
          </Route>
          <Route path="/mining/:single">
            <Mining lang={lang} />
          </Route>
          <Route path="/minings/inviter">
            <Inviter lang={lang} account={account} contracts={contracts} />
          </Route>
          <Route path="/mining">
            <Farms lang={lang} />
          </Route>
          <Route path="/activity">
            <Activity lang={lang} contracts={contracts} account={account}/>
          </Route>
          <Route path="/rules">
            <Rules lang={lang} />
          </Route>
          <Route path="/stats">
            <Stats lang={lang} account={account} contracts={contracts} />
          </Route>
          <Route path="/bonus">
            <Bonus lang={lang} account={account} contracts={contracts} />
          </Route>
       </Switch>
      </Container>

      <div style={{ textAlign: 'center', marginTop: 40, marginBottom: 40 }}>
        <div style={{ fontWeight: 'bold', marginBottom: 12 ,color:"#ffffff"}}>{l10n[lang].contact}</div>
        <>
          <LinkUI rel="noreferrer" className={styles.bottomLink} style={{color:"#ffffff"}}  target="_blank">Twitter</LinkUI>
          <LinkUI rel="noreferrer" className={styles.bottomLink} style={{color:"#ffffff"}}  target="_blank">Medium</LinkUI>
          <LinkUI rel="noreferrer" className={styles.bottomLink} style={{color:"#ffffff"}}  target="_blank">Telegram</LinkUI>
          <LinkUI rel="noreferrer" className={styles.bottomLink} style={{color:"#ffffff"}}  target="_blank">Github</LinkUI>
        </>
      </div>

      <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={snackbar1}
          autoHideDuration={2000}
          message={l10n[lang].txSent}
          onClose={(event: any, reason?: string) => {
            if (reason === 'clickaway') {
              return;
            }

            setSnackbar1(false);
          }}
      />

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackbar}
        autoHideDuration={2000}
        message={l10n[lang].noMetaMaskTips}
        onClose={(event: any, reason?: string) => {
          if (reason === 'clickaway') {
            return;
          }
          setSnackbar(false);
        }}
      />

    </Router>
  );
};

export default App;
