import React from 'react';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Container,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { Props, mining } from '../helpers';


const l10n: { [key: string]: { [key: string]: any } } = {
  'EN': {
    deposit: 'Deposit',
    earn: 'Harvest',
    choose: 'Select',
  },
  '中文': {
    deposit: '存入',
    earn: '收获',
    choose: '选择',
  }
};



const useStyles = makeStyles({
  title: {
    fontSize: '120%',
    fontWeight: 'bold',
    marginTop: 30,
    marginBottom: 30,
    color: '#4c3444'
  },
  card: {
    borderRadius: 15,
    color: '#768b76',
    marginBottom: 2,
    paddingTop: 20,
    width:"100%",
    paddingBottom: 40,
    background:"#17141A"
  },
  iconCtn: {
    fontSize: 36,
    height: 80,
    width: 80,
    borderRadius: 50,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto 16px',
  },

  iconCtn2: {
    fontSize: 36,
    height: 90,
    width: 150,
    borderRadius: 50,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto 16px',
  },
  avatar2: {
    backgroundColor: 'transparent',
    height: 90,
    width: 150,
    fontSize: '110%'
  },
  avatar: {
    backgroundColor: 'transparent',
    height: 70,
    width: 70,
    fontSize: '110%'
  },

  t1: {
    color: '#FFFFFF',
    fontSize: '120%',
    fontWeight: 'bold',
    marginBottom: 20,
  },
  t2: {
    fontSize: '90%',
    marginBottom: 8,
    color: '#737276',
  },
  t3: {
    fontSize: '90%',
    color: 'red',
    marginBottom: 30,
  },
  link: {
    backgroundColor: '#143D3F',
    display: 'block',
    textDecoration: 'none',
    color: '#09E2D5',
    fontSize: '110%',
    paddingTop:'10',
    paddingBottom:'10',
    lineHeight: 2,
    borderRadius: 30,
  }
});

function Page({ lang }: Props) {
  const styles = useStyles();
  const params: any = useParams();
  const coin = params.single;

  console.error('get uni balance: ', coin)
  return (
    <Box style={{ textAlign: 'center' }}>
      <Container>
        <Grid container spacing={2} justify="center">
          {
            mining.map(f => (
              f.type == coin?
              <Grid item xs={12} key={f.id}>
                <Box className={styles.card}>
                <Box className={styles.t1}>{f.name}</Box>
                <Box className={styles.t2}>{l10n[lang].deposit}{f.save}</Box>
                <Box className={styles.t2}>{l10n[lang].earn}{f.has}</Box>
                  {
                    f.type == "1"?
                    <Box className={styles.iconCtn}>
                    <Avatar className={styles.avatar} src={f.icon} />
                  </Box>
                  :<Box className={styles.iconCtn2}>
                  <Avatar className={styles.avatar2} src={f.icon} />
                </Box>
                  }
                  <Box style={{ margin: '0 30px' }}>
                    {
                    <Link
                    to={"/mining/single/" + f.id}
                    className={styles.link}
                  >{l10n[lang].choose}</Link>

                    }
                  </Box>
                </Box>
              </Grid>
            :<Box></Box>
            ))
          }
        </Grid>
      </Container>

    </Box>
  );
}

export default Page;
