import React, { useEffect, useState } from 'react';
import { BigNumber } from 'ethers';
import axios from "axios";


import {
  Avatar,
  Box,
  Button,
  Grid,
  Container,
  Tab, Snackbar,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import {Props, roundEther, round, daojiShi} from '../helpers';
import banner from '../assets/home_banner.png';
import bannerEN from '../assets/home_banner_en.png';
import logo from '../assets/logo.png';
import {IsoOutlined} from "@material-ui/icons";
// import pp = jasmine.pp;
import cookie from 'react-cookies'

const l10n: { [key: string]: { [key: string]: any } } = {
  'EN': {
    countW: "Total withdraw of HWCMDPRO",
    priceW: "HWCMDPRO price",
    unclaimed: "Mine Award",
    recommended: "Referrer Award",
    aprTitle: "Pool overview",
    tab1: "Ore pool",
    tab2: "Total pledged",
    tab3: "Total Value in USD",
    rankingaward: "Ranking Award",
    // txSent:"You have a new notification"
  },
  '中文': {
    countW: "HWCMDPRO总提出",
    priceW: "HWCMDPRO价格",
    unclaimed: "我的矿池待领取",
    recommended: "我的推荐收益待领取",
    aprTitle: "矿池概览",
    tab1: "矿池",
    tab2: "质押总量",
    tab3: "总价值",
    rankingaward: "质押排行奖励",
    // txSent:"你有新的通知"
  }
};

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: '#4c3444',
  },
  cardCtn: {
    marginTop: 40,
  },
  card: {
    border: '1px solid #17141A',
    boxShadow: '1px 1px 9px #09E2D5,-15px -15px 24px -66px #09E2D5',
    borderRadius: 5,
    paddingTop: 30,
    marginTop: 30,
    width: "100%",
    color: '#17141A',
    marginBottom: 2,
    background: "#17141A"
  },
  amount: {
    color: '#4c3444',
    fontSize: '180%',
    fontWeight: 'bold'
  },

  t1: {
    color: '#737276',
    fontSize: '90%',

  },
  t2: {
    color: '#09E2D5',
    fontSize: '160%',
    fontWeight: 'bold',
    marginBottom: 20,
  },

  cardApr: {
    background: "#17141A",
    marginTop: "20px",
    paddingTop: "20px",
    display: 'block',
    textAlign: "center",
    paddingBottom: "20px",
    borderRadius: "10px",
    overflow: "auto"
  },
  aprText: {
    color: "#ffffff",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "10px",
  },
  aprHead: {
    display: "table-row",
    fontSize: "13px",

  },
  trStyle: {
    color: '#ffffff', whiteSpace: "nowrap", padding: "2px", fontSize: "12px"
  }
});
function useStats(account: any, contracts: any) {
  // console.log("111111")

  const [totalSupply1, settotalSupply1] = useState(BigNumber.from(0));
  const [totalSupply2, settotalSupply2] = useState(BigNumber.from(0));
  const [totalSupply3, settotalSupply3] = useState(BigNumber.from(0));
  const [totalSupply4, settotalSupply4] = useState(BigNumber.from(0));
  const [totalSupply5, settotalSupply5] = useState(BigNumber.from(0));
  const [totalSupply6, settotalSupply6] = useState(BigNumber.from(0));
  const [totalSupply7, settotalSupply7] = useState(BigNumber.from(0));
  const [totalSupply8, settotalSupply8] = useState(BigNumber.from(0));


  const [APY1, setAPY1] = useState(BigNumber.from(0));
  const [APY2, setAPY2] = useState(BigNumber.from(0));
  const [APY3, setAPY3] = useState(BigNumber.from(0));
  const [APY4, setAPY4] = useState(BigNumber.from(0));
  const [APY5, setAPY5] = useState(BigNumber.from(0));
  const [APY6, setAPY6] = useState(BigNumber.from(0));
  const [APY7, setAPY7] = useState(BigNumber.from(0));
  const [APY8, setAPY8] = useState(BigNumber.from(0));
  // const [APY9, setAPY9] = useState(Number(0));
  // const [APY10, setAPY10] = useState(Number(0));
  const [uwcount,setUwcount] = useState(BigNumber.from(0));
  const [uucount,setUucount] = useState(BigNumber.from(0));
  const [bwcount,setBwcount] = useState(BigNumber.from(0));
  const [bbcount,setBbcount] = useState(BigNumber.from(0));
  const [ewcount,setEwcount] = useState(BigNumber.from(0));
  const [eecount,setEecount] = useState(BigNumber.from(0));
  const [lwcount,setLwcount] = useState(BigNumber.from(0));
  const [llcount,setLlcount] = useState(BigNumber.from(0));
  const [bprice, setBprice] = useState(BigNumber.from(0));
  const [eprice, setEprice] = useState(BigNumber.from(0));
  const [lprice, setLprice] = useState(BigNumber.from(0));
  const [wur0, setWur0] = useState([BigNumber.from(0),BigNumber.from(0)]);
  const [wbr0, setWbr0] = useState([BigNumber.from(0),BigNumber.from(0)]);
  const [wer0, setWer0] = useState([BigNumber.from(0),BigNumber.from(0)]);
  const [wlr0, setWlr0] = useState([BigNumber.from(0),BigNumber.from(0)]);
  const [hwwTotal,setHwwTotal] = useState(BigNumber.from(0));
  const [hwbTotal,setHwbTotal] = useState(BigNumber.from(0));
  const [hweTotal,setHweTotal] = useState(BigNumber.from(0));
  const [hwlTotal,setHwlTotal] = useState(BigNumber.from(0));
  var data = useState([[]]);

  useEffect(() => {
    const work1 = async () => {
      console.log("wcmd 1  ")
      if (!account || !contracts) {
        return;
      }
      // contracts.usdtLp.contract.getReserves()
      //     .then((r: any) => {
      //       console.log("----")
      //       console.log(r[0])
      //   setUwcount(r[0]);
      //   setUucount(r[1]);
      //
      // })
      //     .catch((err: any) => {
      //       console.error('usdt: ', err.message)
      //     });
      //
      // contracts.usdtHtbcLp.contract.getReserves()
      //     .then((r: any) => {
      //       setBwcount(r[0]);
      //       setBbcount(r[1]);
      //     })
      //     .catch((err: any) => {
      //       console.error('releaseTotal: ', err.message)
      //     });
      //
      // contracts.UsdtHEthLp.contract.getReserves()
      //     .then((r :  any) => {
      //       setEwcount(r[0]);
      //       setEecount(r[1]);
      //     })
      //     .catch((err: any) => {
      //       console.error('releaseTotal: ', err.message)
      //     });
      //
      // contracts.UsdtHLtcLp.contract.getReserves()
      //     .then((r: any) => {
      //       setLwcount(r[0]);
      //       setLlcount(r[1]);
      //     })
      //     .catch((err: any) => {
      //       console.error('releaseTotal: ', err.message)
      //     });
      // console.log("read contract")
      // const [usdtWcmdCont, usdtCont] = await contracts.usdtLp.contract.getReserves();
      // console.log("usdt")
      // console.log(usdtCont)
      // console.log("wcmd")
      // console.log(usdtWcmdCont)

      // const [usdtCont, htCont] = await contracts.usdtLp.contract.getReserves();
      const [hbuCont, hbtcCont] = await contracts.usdtHtbcLp.contract.getReserves();

      const [heuCont, hethCont] = await contracts.UsdtHEthLp.contract.getReserves();
      const [hluCont, hltcCont] = await contracts.UsdtHLtcLp.contract.getReserves();


        const [hwwCount, hwuCount] = await contracts.Wcmdhulp.contract.getReserves();
        const wwTotal = await contracts.Wcmdhulp.contract.totalSupply();
        setHwwTotal(wwTotal);
        const [hbwCount, hbbCount] = await contracts.Wcmdhblp.contract.getReserves();
        const wbTotal =  await contracts.Wcmdhblp.contract.totalSupply();
        setHwbTotal(wbTotal);
        const [hewCount, heeCount] = await contracts.Wcmdhelp.contract.getReserves();
        const weTotal = await contracts.Wcmdhelp.contract.totalSupply();
        setHweTotal(weTotal);
        const [hlwCount, hllCount] = await contracts.Wcmdhllp.contract.getReserves();
        const wlTotal =  await contracts.Wcmdhllp.contract.totalSupply();
        setHwlTotal(wlTotal);
        setWur0([hwwCount,hwuCount]);
        setWbr0([hbwCount,hbbCount]);
        setWer0([hewCount,heeCount]);
        setWlr0([hlwCount,hllCount]);
        const eprice1 = hethCont.mul(BigNumber.from(10).pow(18)).div(heuCont);
        const bprice1 = hbuCont.mul(BigNumber.from(10).pow(18)).div(hbtcCont);
        const lprice1 = hluCont.mul(BigNumber.from(10).pow(18)).div(hltcCont);
        setEprice(eprice1);
        setBprice(bprice1);
        setLprice(lprice1);
        console.log("price");
        console.log(eprice);

      const pc = contracts.pool.contract;
      // const price =  usdtCont/usdtWcmdCont;
      // let countNumber = 1000000000000000000;

      var totalStakedWCMD = BigNumber.from(0);
     //  pc.totalStaked(1)
     //      .then((r0: BigNumber) => {
     //    totalStakedWCMD = r0;
     //    settotalSupply1(totalStakedWCMD.mul(htCont).div(htWcmdCont));
     //  }).catch((err: any) => {
     //    console.error('w: ', err.message)
     // });


      // var totalStakedHBTC = BigNumber.from(0);
      // pc.totalStaked(2)
      //     .then((r0: BigNumber) => {
      //       totalStakedHBTC= r0;
      //       // settotalSupply2(totalStakedHBTC.mul(hbtcCont).div(hb));
      //     }).catch((err: any) => {
      //   console.error('b : ', err.message)
      // });
      // var totalStakedHETH = BigNumber.from(0);
      // pc.totalStaked(3)
      //     .then((r0: BigNumber) => {
      //       totalStakedHETH = r0;
      //       // settotalSupply3(totalStakedHETH.mul(hethCont).div(hethWcmdCont));
      //     }).catch((err: any) => {
      //   console.error('e: ', err.message)
      // });
      // var totalStakedHLTC = BigNumber.from(0);
      // pc.totalStaked(4)
      //     .then((r0: BigNumber) => {
      //       totalStakedHLTC = r0;
      //       // settotalSupply4(totalStakedHLTC.mul(hltcCont).div(hltcWcmdCont));
      //     }).catch((err: any) => {
      //   console.error('l: ', err.message)
      // });
      // var totalStakedLPUW = BigNumber.from(0);
      // pc.totalStaked(5)
      //     .then((r0: BigNumber) => {
      //       totalStakedLPUW = r0;
      //       settotalSupply5(totalStakedLPUW);
      //     }).catch((err: any) => {
      //   console.error('releaseTotal: ', err.message)
      // });
      // var totalStakedLPBW = BigNumber.from(0);
      // pc.totalStaked(6)
      //     .then((r0: BigNumber) => {
      //       totalStakedLPBW = r0;
      //       settotalSupply6(totalStakedLPBW);
      //     }).catch((err: any) => {
      //   console.error('releaseTotal: ', err.message)
      // });
      // var totalStakedLPEW = BigNumber.from(0);
      // pc.totalStaked(7)
      //     .then((r0: BigNumber) => {
      //       totalStakedLPEW = r0;
      //       settotalSupply7(totalStakedLPEW);
      //     }).catch((err: any) => {
      //   console.error('releaseTotal: ', err.message)
      // });
      // var totalStakedLPLW = BigNumber.from(0);
      // pc.totalStaked(8)
      //     .then((r0: BigNumber) => {
      //       totalStakedLPLW = r0;
      //       settotalSupply8(totalStakedLPLW);
      //     }).catch((err: any) => {
      //   console.error('releaseTotal: ', err.message)
      // });

      // data = await contracts.pool.contract.getTotalStakedInUSDT();
      // console.log(data);
      // console.log(data[0][2])
            // .then((data:any)=>{
            //     // console.log("xx")
            //     // console.log(data)
            //     setTt(data);
            //     console.log(tt);
            //     console.log(tt[0][2])
            // })
            // .catch((err: any)=>{
            //     console.error("gettotalstake: ", err.message)
            // })
      // const totalSupplyWCMD = await pc.totalStaked(1);
      //
      // const totalSupplyHBTC = await pc.totalStaked(2);
      // const totalSupplyHETH = await pc.totalStaked(3);
      // const totalSupplyHLTC = await pc.totalStaked(4);
      // const totalSupplyHFIL = await pc.totalStaked(5);
      // const totalSupplyUSDT = await pc.totalStaked(6);
      // const totalSupplyMDX = await pc.totalStaked(7);
      // settotalSupply1((usdtCont/totalSupplyWCMD)*(totalSupplyWCMD/countNumber))
      // settotalSupply2((usdtCont/totalSupplyHBTC)*(totalSupplyHBTC/countNumber))
      // settotalSupply3((usdtCont/totalSupplyHETH)*(totalSupplyHETH/countNumber))
      // settotalSupply4((usdtCont/totalSupplyHLTC)*(totalSupplyHLTC/countNumber))
      // console.log("wcmd  " + totalSupplyWCMD)
      // settotalSupply4((usdtCont/totalSupplyHTBH)*(totalSupplyHTBH/countNumber))
      // settotalSupply5((usdtCont/totalSupplyHFIL)*(totalSupplyHFIL/countNumber))
      // settotalSupply6((usdtCont/totalSupplyUSDT)*(totalSupplyUSDT/countNumber))
      // settotalSupply7((usdtCont/totaslSupplyMDX)*(totalSupplyMDX/countNumber))

      // console.error('totalStakedPerPool',usdtCont/totalSupplyWCMD)


      // const totalSupplyWCMD_USDT = await pc.totalStaked(5);
      // const totalSupplyUSDT_HBTC = await pc.totalStaked(6);
      // const totalSupplyUSDT_HETH = await pc.totalStaked(7);
      // const totalSupplyUSDT_HLTC = await pc.totalStaked(8);
      // settotalSupply5(((totalSupplyWCMD_USDT/countNumber)/300000)*(usdtWcmdCont/countNumber)*price*2)
      // // settotalSupply9(((totalSupplyUSDT_HT/countNumber)/250000)*(htWcmdCont/countNumber)*price*2)
      // settotalSupply6(((totalSupplyUSDT_HBTC/countNumber)/150000)*(hbtcWcmdCont/countNumber)*price*2)


      // setAPY1((30000*price*365)/((usdtCont/totalSupplyWCMD)*(totalSupplyWCMD/countNumber)))
      // setAPY2((30000*price*365)/((usdtCont/totalSupplyHBTC)*(totalSupplyHBTC/countNumber)))
      // setAPY3((30000*price*365)/((usdtCont/totalSupplyHETH)*(totalSupplyHETH/countNumber)))
      // setAPY4((30000*price*365)/((usdtCont/totalSupplyHLTC)*(totalSupplyHLTC/countNumber)))
      // setAPY5((420000*price*365)/(((totalSupplyWCMD_USDT/countNumber)/300000)*(usdtWcmdCont/countNumber)*price*2))
      // setAPY6((100000*price*365)/(((totalSupplyUSDT_HBTC/countNumber)/150000)*(hbtcWcmdCont/countNumber)*price*2))
      // setAPY7((100000*price*365)/(((totalSupplyUSDT_HETH/countNumber)/150000)*(hethWcmdCont/countNumber)*price*2))
      // setAPY8((100000*price*365)/(((totalSupplyUSDT_HLTC/countNumber)/150000)*(hltcWcmdCont/countNumber)*price*2))

      // setReleaseTotal(contracts.pool.contract.releaseTotal;
    };

    work1();

    const t = setInterval(work1, 20000);

    return () => clearInterval(t);
  }, [account, contracts]);

  return [
    [bprice,eprice,lprice],[wur0,wbr0,wer0,wlr0],[hwwTotal,hwbTotal,hweTotal,hwlTotal]
  ];
}




function Page({ lang, account, contracts }: Props) {
  const styles = useStyles();
  // const [pprice,count,total] = useStats(account, contracts);
  // console.log(total)
  const [referrer, setReferrer] = useState(BigNumber.from(0));
  const [rankAward, setRankAward] = useState(BigNumber.from(0));


  const [releaseTotal, setReleaseTotal] = useState(BigNumber.from(0));
  const [poolTotal, setPoolTotal] = useState(BigNumber.from(0));
  const [price, setPrice] = useState(Number(0));
  const [tt ,setTt] = useState( [   {worth:BigNumber.from(0),total:BigNumber.from(0)},{worth:BigNumber.from(0),total:BigNumber.from(0)},
                                              {worth:BigNumber.from(0),total:BigNumber.from(0)},{worth:BigNumber.from(0),total:BigNumber.from(0)},
                                              {worth:BigNumber.from(0),total:BigNumber.from(0)},{worth:BigNumber.from(0),total:BigNumber.from(0)},
                                              {worth:BigNumber.from(0),total:BigNumber.from(0)},{worth:BigNumber.from(0),total:BigNumber.from(0)}]);

  const [wu,setWu] = useState(BigNumber.from(0));
  const [wb,setWb] = useState(BigNumber.from(0));
  const [we,setWe] = useState(BigNumber.from(0));
  const [wl,setWl] = useState(BigNumber.from(0));
  const [snackbar, setSnackbar] = useState(false);

  // const [bprice, setBprice] = useState(BigNumber.from(0));
  // const [eprice, setEprice] = useState(BigNumber.from(0));
  // const [lprice, setLprice] = useState(BigNumber.from(0));
  const [wur0, setWur0] = useState([BigNumber.from(0),BigNumber.from(0)]);
  const [wbr0, setWbr0] = useState([BigNumber.from(0),BigNumber.from(0)]);
  const [wer0, setWer0] = useState([BigNumber.from(0),BigNumber.from(0)]);

  const [wlr0, setWlr0] = useState([BigNumber.from(0),BigNumber.from(0)]);
  const [hwuTotal,setHwuTotal] = useState(BigNumber.from(0));
  const [hwbTotal,setHwbTotal] = useState(BigNumber.from(0));
  const [hweTotal,setHweTotal] = useState(BigNumber.from(0));
  const [hwlTotal,setHwlTotal] = useState(BigNumber.from(0));



  // const bprice = BigNumber.from(0);
  // const eprice = BigNumber.from(0);
  // const lprice = BigNumber.from(0);
  //
  // const wur0 = BigNumber.from(0);
  // const wur2 = BigNumber.from(0);
  //
  // const wbr0 = BigNumber.from(0);
  // const wbr1 = BigNumber.from(0);
  //
  // const wer0 = BigNumber.from(0);
  // const wer1 = BigNumber.from(0);
  //
  // const wlr0 = BigNumber.from(0);
  // const wlr1 = BigNumber.from(0);

  function load1(){
    axios.get('https://api.wcmd.pro:444/api/getInfo')
        .then((response:any)=>{
          if(response.data.status==0){
            console.log("done")
            if(!tt[4].total.isZero()){
              // BigNumber.from("0x01")
              // console.log(response.data.data)
              // console.log(response.data.data.wulpt)
              // console.log(BigNumber.from(response.data.data.wulpt));
              const per = tt[4].total.mul(BigNumber.from(10).pow(18)).div(BigNumber.from(response.data.data.wulpt));
              setWu(BigNumber.from(response.data.data.wulpr.r1).mul(per).mul(BigNumber.from(2)))
              // console.log("---4---")
              // console.log(roundEther(wu,6));
            }
            if(!tt[5].total.isZero() ){
              const per = tt[5].total.mul(BigNumber.from(10).pow(18)).div(BigNumber.from(response.data.data.wblpt));
              // const per = tt[6].total.mul(BigNumber.from(10).pow(18)).div(hwbTotal);
              setWb(BigNumber.from(response.data.data.wulpr.r0).mul(per).mul(BigNumber.from(2)).mul(response.data.data.wulpr.r1).div(response.data.data.wulpr.r0))
              // console.log("---3---")
              // console.log(roundEther(wb,6));
            }
            if(!tt[6].total.isZero()){
              const per = tt[6].total.mul(BigNumber.from(10).pow(18)).div(BigNumber.from(response.data.data.welpt));
              // const per = tt[6].total.mul(BigNumber.from(10).pow(18)).div(hwbTotal);
              setWe(BigNumber.from(response.data.data.welpr.r0).mul(per).mul(BigNumber.from(2)).mul(response.data.data.wulpr.r1).div(response.data.data.wulpr.r0))
              // const per = tt[6].total.mul(BigNumber.from(10).pow(18)).div(hweTotal);
              // setWe(wer0[0].mul(per).mul(BigNumber.from(2)).mul(wur0[1]).div(wur0[0]))
              // console.log("---2---")
              // console.log(roundEther(we,6));
            }
            if(!tt[7].total.isZero() ){
              const per = tt[7].total.mul(BigNumber.from(10).pow(18)).div(BigNumber.from(response.data.data.wllpt));
              // const per = tt[6].total.mul(BigNumber.from(10).pow(18)).div(hwbTotal);
              setWl(BigNumber.from(response.data.data.wllpr.r0).mul(per).mul(BigNumber.from(2)).mul(response.data.data.wulpr.r1).div(response.data.data.wulpr.r0))
              // const per = tt[7].total.mul(BigNumber.from(10).pow(18)).div(hwlTotal);
              // setWl(wlr0[0].mul(per).mul(BigNumber.from(2)).mul(wur0[1]).div(wur0[0]))
              // console.log("---1---")
              // console.log(roundEther(wl,6));
            }

          }
          // if(response.status)
        })
    // console.log("load");
    // console.log(tt[4].total);
    // console.log(wur0[0]);
    // console.log(hwuTotal)
    // console.log("-----")
    //
    // if(!tt[4].total.isZero() && !wur0[0].isZero() && !hwuTotal.isZero()){
    //   const per = tt[4].total.mul(BigNumber.from(10).pow(18)).div(hwuTotal);
    //   setWu(wur0[1].mul(per).mul(BigNumber.from(2)))
    //   console.log("---4---")
    //   console.log(roundEther(wu,6));
    // }
    // if(!tt[5].total.isZero() && !wbr0[0].isZero() && !hwbTotal.isZero()&& !wur0[0].isZero()){
    //   const per = tt[6].total.mul(BigNumber.from(10).pow(18)).div(hwbTotal);
    //   setWb(wbr0[0].mul(per).mul(BigNumber.from(2)).mul(wur0[1]).div(wur0[0]))
    //   console.log("---3---")
    //   console.log(roundEther(wb,6));
    // }
    // if(!tt[6].total.isZero() && !wer0[0].isZero() && !hweTotal.isZero()&& !wur0[0].isZero()){
    //   const per = tt[6].total.mul(BigNumber.from(10).pow(18)).div(hweTotal);
    //   setWe(wer0[0].mul(per).mul(BigNumber.from(2)).mul(wur0[1]).div(wur0[0]))
    //   console.log("---2---")
    //   console.log(roundEther(we,6));
    // }
    // if(!tt[7].total.isZero() && !wlr0[0].isZero() && !hwlTotal.isZero()&& !wur0[0].isZero()){
    //   const per = tt[7].total.mul(BigNumber.from(10).pow(18)).div(hwlTotal);
    //   setWl(wlr0[0].mul(per).mul(BigNumber.from(2)).mul(wur0[1]).div(wur0[0]))
    //   console.log("---1---")
    //   console.log(roundEther(wl,6));
    // }
    console.log("---0---")
  }

  useEffect(() => {


    const work = () => {

      // @ts-ignore
      // setWl(BigNumber.from(Math.random()).mul(BigNumber.from(10).pow(18))) ;
      if (!account || !contracts) {
        return;
      }
      //
      // contracts.usdtHtbcLp.contract.getReserves()
      //     .then((data:any)=>{
      //
      //     })

      // const [hbuCont, hbtcCont] = await contracts.usdtHtbcLp.contract.getReserves();
      // const [heuCont, hethCont] = await contracts.UsdtHEthLp.contract.getReserves();
      // const [hluCont, hltcCont] = await contracts.UsdtHLtcLp.contract.getReserves();

      // contracts.usdtHtbcLp.contract.getReserves()
      //     .then((data:any)=>{
      //
      //     });

      // const [hwwCount, hwuCount] = await contracts.Wcmdhulp.contract.getReserves();
      // contracts.Wcmdhulp.contract.getReserves()
      //     .then((data:any)=>{
      //       setWur0([data[0],data[1]])
      //       wur0[0] = data[0];
      //       wur0[1] = data[1];
      //       console.log(1);
      //       console.log(data[0]);
      //       load1();
      //     })
      //
      // contracts.Wcmdhblp.contract.getReserves()
      //     .then((data:any)=>{
      //       wbr0[0] = data[0];
      //       wbr0[1] = data[1];
      //       setWbr0([data[0],data[1]])
      //       console.log(2);
      //       console.log(data[0]);
      //       load1();
      //     })
      // contracts.Wcmdhelp.contract.getReserves()
      //     .then((data:any)=>{
      //       wer0[0] = data[0];
      //       wer0[1] = data[1];
      //       setWer0([data[0],data[1]])
      //       console.log(3);
      //       console.log(data[0]);
      //       load1();
      //     })
      // contracts.Wcmdhllp.contract.getReserves()
      //     .then((data:any)=>{
      //       wlr0[0] = data[0];
      //       wlr0[1] = data[1];
      //       setWlr0([data[0],data[1]])
      //       console.log(4);
      //       console.log(data[0]);
      //       load1();
      //     })
      // contracts.Wcmdhulp.contract.totalSupply()
      //     .then((data:BigNumber)=>{
      //       setHwuTotal(data);
      //       console.log(5);
      //       console.log(data);
      //       load1();
      //     })
      // contracts.Wcmdhblp.contract.totalSupply()
      //     .then((data:BigNumber)=>{
      //       setHwbTotal(data);
      //       console.log(6);
      //       console.log(data);
      //       load1();
      //     })
      // contracts.Wcmdhelp.contract.totalSupply()
      //     .then((data:BigNumber)=>{
      //       setHweTotal(data);
      //       console.log(7);
      //
      //       console.log(data);
      //       load1();
      //     })
      // contracts.Wcmdhllp.contract.totalSupply()
      //     .then((data:BigNumber)=>{
      //       setHwlTotal(data);
      //       console.log(8);
      //       console.log(data);
      //       load1();
      //     })



      // const wwTotal = await contracts.Wcmdhulp.contract.totalSupply();
      // setHwwTotal(wwTotal);
      // const [hbwCount, hbbCount] = await contracts.Wcmdhblp.contract.getReserves();
      // const wbTotal =  await contracts.Wcmdhblp.contract.totalSupply();
      // setHwbTotal(wbTotal);
      // const [hewCount, heeCount] = await contracts.Wcmdhelp.contract.getReserves();
      // const weTotal = await contracts.Wcmdhelp.contract.totalSupply();
      // setHweTotal(weTotal);
      // const [hlwCount, hllCount] = await contracts.Wcmdhllp.contract.getReserves();
      // const wlTotal =  await contracts.Wcmdhllp.contract.totalSupply();
      // setHwlTotal(wlTotal);
      // setWur0([hwwCount,hwuCount]);
      // setWbr0([hbwCount,hbbCount]);
      // setWer0([hewCount,heeCount]);
      // setWlr0([hlwCount,hllCount]);
      // const eprice1 = hethCont.mul(BigNumber.from(10).pow(18)).div(heuCont);
      // const bprice1 = hbuCont.mul(BigNumber.from(10).pow(18)).div(hbtcCont);
      // const lprice1 = hluCont.mul(BigNumber.from(10).pow(18)).div(hltcCont);
      // setEprice(eprice1);
      // setBprice(bprice1);
      // setLprice(lprice1);


      // if(pprice.length>0 && count.length>0 && total.length>0){
      //     // @ts-ignore
      //     if(!tt[4].total.isZero()&&!total[0].isZero()){
      //         // @ts-ignore
      //         const per = tt[4].total.mul(BigNumber.from(10).pow(18)).div(total[0]);
      //         console.log(roundEther(per,6))
      //         console.log(roundEther(tt[4].total,6))
      //         // @ts-ignore
      //         console.log(roundEther(total[0],6))
      //         // @ts-ignore
      //         setWu(count[0][1].mul(per).mul(BigNumber.from(2)));
      //         // console.log(wu)
      //     }
      //     // @ts-ignore
      //     if(!tt[5].total.isZero()&&!total[1].isZero()&&!pprice[0].isZero()){
      //         // @ts-ignore
      //         // console.log(roundEther(total[1]))
      //         // console.log(roun)
      //         // @ts-ignore
      //         const per = tt[5].total.mul(BigNumber.from(10).pow(18)).div(total[1]);
      //         console.log("per")
      //         console.log(per)
      //         // @ts-ignore
      //         setWb(count[1][0].mul(per).mul(count[0][1]).div(count[0][0]).mul(2));
      //     }
      //     // @ts-ignore
      //     if(!tt[6].total.isZero()&&!total[2].isZero()&&!pprice[1].isZero()){
      //         // @ts-ignore
      //         const per = tt[6].total.mul(BigNumber.from(10).pow(18)).div(total[2]);
      //         console.log(roundEther(per,6));
      //         // @ts-ignore
      //         console.log(roundEther(total[2],6))
      //         console.log(roundEther(tt[6].total,6))
      //         // @ts-ignore
      //         setWe(count[2][0].mul(per).mul(count[0][1]).div(count[0][0]).mul(2));
      //     }
      //     // @ts-ignore
      //     if(!tt[7].total.isZero()&&!total[3].isZero()&&!pprice[2].isZero()){
      //         // @ts-ignore
      //         const per = tt[7].total.mul(BigNumber.from(10).pow(18)).div(total[3]);
      //         // @ts-ignore
      //         setWl(count[3][0].mul(per).mul(count[0][1]).div(count[0][0]).mul(2));
      //
      //     }
      // }

      contracts.pool.contract.getTotalStakedInUSDT()
          .then((data:any)=>{
              console.log(data)
             for(var i = 0 ;i < 8 ;i++){
                 tt[i].worth = data[i][2];
                 tt[i].total = data[i][1];

             }
      load1();
          })
          .catch((err: any)=>{
              console.error("gettotalstake: ", err.message)
          })
      contracts.pool.contract.releaseTotal()
        .then((amount: BigNumber) => {
          setReleaseTotal(amount)
        })
        .catch((err: any) => {
          console.error('releaseTotal 1: ', err.message)
        });

      contracts.pool.contract.myPoolToBeWithdraw(account)
        .then((amount: BigNumber) => {
          setPoolTotal(amount)
        })
        .catch((err: any) => {
          console.error('myPoolToBeWithdraw 2: ', err.message)
        });

      // console.log(account)
      contracts.pool.contract.userInfo(account)
        .then((res: any) => {
            console.log(res)
          setReferrer(res.referedRewards)
        })
        .catch((err: any) => {
          console.error('userInfo:  s ', err.message)
        });

      contracts.usdtLp.contract.getReserves()
          .then((r :any) => {
            let number = r[1]/(r[0]);
            setPrice(number)
          })
          .catch((err: any) => {
            console.error('get price: ', err.message)
          });
      contracts.pool.contract.checkTotalRewards()
          .then((r: any)=>{
              // console.log("111")
              // console.log(r)
            setRankAward(r)
          }).catch((err:any) =>{
            console.error('get reward error: ',err.message)
      });
      console.log("end-=---");
    };

    // cookie.save("test","hello world",{path:"/"})
    //
    // console.log(cookie.load("test"))

    work();
    // setTimeout(()=>{
    //   work();
    // }, 1000)

    const t = setInterval(work, 20000);

    // return () => clearInterval(t);
  }, [account, contracts]);



  return (
    <Box style={{ textAlign: 'center' }}>
      <Box>
        <img src={lang === 'EN' ? bannerEN : banner} alt="banner" width="100%" />
      </Box>

      <Box className={styles.card}>
        <Box className={styles.t1}>{l10n[lang].countW} </Box>
        <Box className={styles.t2}>{roundEther(releaseTotal, 6)}</Box>
        <Box className={styles.t1}>{l10n[lang].priceW} </Box>
        <Box className={styles.t2}>{round(price.toString(), 6)}</Box>
        <Box className={styles.t1}>{l10n[lang].unclaimed} </Box>
        <Box className={styles.t2}>{roundEther(poolTotal, 6)}</Box>
        <Box className={styles.t1}>{l10n[lang].recommended} </Box>
        <Box className={styles.t2}>{roundEther(referrer, 6)}</Box>
        <Box className={styles.t1}>{l10n[lang].rankingaward} </Box>
        <Box className={styles.t2}>{roundEther(rankAward, 6)}</Box>

      </Box>


      <Box className={styles.cardApr}>
        <Box className={styles.aprText}>{l10n[lang].aprTitle}</Box>
        <table style={{ width: "100%", borderSpacing: "10px 15px", paddingLeft: '10px', paddingRight: '10px' }}>
          <tr className={styles.aprHead}>
            <td style={{ color: '#737276', whiteSpace: "nowrap", padding: "2px", textAlign: 'left' }}>{l10n[lang].tab1}</td>
            <td style={{ color: '#737276', whiteSpace: "nowrap", textAlign: 'center' }}>{l10n[lang].tab2}</td>
            <td style={{ color: '#737276', whiteSpace: "nowrap", textAlign: 'right' }}>{l10n[lang].tab3}</td>
          </tr>


          <tr className={styles.aprHead}>
              <td className={styles.trStyle} style={{ textAlign: 'left' }}>HWCMDPRO</td>
              <td className={styles.trStyle} style={{ textAlign: 'center' }}>{tt[0].total.isZero()?'0.0':roundEther(tt[0].total,6)}</td>
              <td className={styles.trStyle} style={{ textAlign: 'right' }}>{tt[0].worth.isZero()?'0.0':roundEther(tt[0].worth,6)}</td>
          </tr>

          <tr className={styles.aprHead}>
            <td className={styles.trStyle} style={{ textAlign: 'left' }}>HBTC</td>
              <td className={styles.trStyle} style={{ textAlign: 'center' }}>{tt[1].total.isZero()?'0.0':roundEther(tt[1].total,6)}</td>
              <td className={styles.trStyle} style={{ textAlign: 'right' }}>{tt[1].worth.isZero()?'0.0':roundEther(tt[1].worth,6)}</td>
          </tr>
          <tr className={styles.aprHead}>
            <td className={styles.trStyle} style={{ textAlign: 'left' }}>HETH</td>
              <td className={styles.trStyle} style={{ textAlign: 'center' }}>{tt[2].total.isZero()?'0.0':roundEther(tt[2].total,6)}</td>
              <td className={styles.trStyle} style={{ textAlign: 'right' }}>{tt[2].worth.isZero()?'0.0':roundEther(tt[2].worth,6)}</td>
          </tr>

          <tr className={styles.aprHead}>
            <td className={styles.trStyle} style={{ textAlign: 'left' }}>HLTC</td>
              <td className={styles.trStyle} style={{ textAlign: 'center' }}>{tt[3].total.isZero()?'0.0':roundEther(tt[3].total,6)}</td>
              <td className={styles.trStyle} style={{ textAlign: 'right' }}>{tt[3].worth.isZero()?'0.0':roundEther(tt[3].worth,6)}</td>
          </tr>


          <tr className={styles.aprHead}>
            <td className={styles.trStyle} style={{ textAlign: 'left' }}>HWCMDPRO/USDT</td>
              <td className={styles.trStyle} style={{ textAlign: 'center' }}>{tt[4].total.isZero()?'0.0':roundEther(tt[4].total,6)}</td>
              <td className={styles.trStyle} style={{ textAlign: 'right' }}>{wu.isZero()?'---':roundEther(wu.div(BigNumber.from(10).pow(18)),6)}</td>
          </tr>

          <tr className={styles.aprHead}>
            <td className={styles.trStyle} style={{ textAlign: 'left' }}>HWCMDPRO/HBTC</td>
              <td className={styles.trStyle} style={{ textAlign: 'center' }}>{tt[5].total.isZero()?'0.0':roundEther(tt[5].total,6)}</td>
              <td className={styles.trStyle} style={{ textAlign: 'right' }}>{wb.isZero()?'---':roundEther(wb.div(BigNumber.from(10).pow(18)),6)}</td>
          </tr>

          <tr className={styles.aprHead}>
            <td className={styles.trStyle} style={{ textAlign: 'left' }}>HWCMDPRO/HETH</td>
              <td className={styles.trStyle} style={{ textAlign: 'center' }}>{tt[6].total.isZero()?'0.0':roundEther(tt[6].total,6)}</td>
              <td className={styles.trStyle} style={{ textAlign: 'right' }}>{we.isZero()?'---':roundEther(we.div(BigNumber.from(10).pow(18)),6)}</td>
              {/*<td className={styles.trStyle} style={{ textAlign: 'right' }}>{tt[6].worth.isZero()?'0.0':roundEther(tt[6].worth.div(BigNumber.from(10).pow(22)),6)}</td>*/}
              {/*<td className={styles.trStyle} style={{ textAlign: 'right' }}>---</td>*/}
          </tr>
          <tr className={styles.aprHead}>
            <td className={styles.trStyle} style={{ textAlign: 'left' }}>HWCMDPRO/HLTC</td>
              <td className={styles.trStyle} style={{ textAlign: 'center' }}>{tt[7].total.isZero()?'0.0':roundEther(tt[7].total,6)}</td>
              <td className={styles.trStyle} style={{ textAlign: 'right' }}>{wl.isZero()?'---':roundEther(wl.div(BigNumber.from(10).pow(18)),6)}</td>
              {/*<td className={styles.trStyle} style={{ textAlign: 'right' }}>{tt[7].worth.isZero()?'0.0':roundEther(tt[7].worth.div(BigNumber.from(10).pow(24)),6)}</td>*/}
              {/*<td className={styles.trStyle} style={{ textAlign: 'right' }}>---</td>*/}
          </tr>

        </table>
      </Box>

    </Box>
  );
}

export default Page;
