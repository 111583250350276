import { useState, useEffect } from 'react';
import { BigNumber,ethers } from "ethers";
// import Cookie from Cookie

import { mining } from './helpers';
import { erc20ABI, poolABI,usdtLpABI,LpABI } from './contracts';

declare global {
  var ethereum: any;
}

// WAS
const poolContract = '0x2718b4624513f78BBf3E958FBC41A68f3EBD0abe';
const UsdtLp = '0x43E534fa0BF38d2823232f377D8343Aa7a622804';
const UsdtHBTCLp = '0xFBe7b74623e4be82279027a286fa3A5b5280F77c';
const UsdtHLtcLp = '0x78C90d3f8A64474982417cDB490E840c01E516D4';
const UsdtHEthLp = '0x060B4bfcE16D15A943ec83C56C87940613e162eB';
const Wcmdhblp = '0xA71ef820a566Aa156dd91A51Bf5c1f5e3De164A5';
const Wcmdhulp = '0x43E534fa0BF38d2823232f377D8343Aa7a622804';
const Wcmdhelp = '0x5886b49D84E8eCB17d4dB6580051467C7F925aa4';
const Wcmdhllp = '0x624DaabaebEf9B185996e4a4C6fCFDcA6c21Da67';

function loadContracts(signer: any) {
  const contracts: { [key: string]: { [key: string]: any } } = {};

  contracts['pool'] = {
    address: poolContract,
    contract: new ethers.Contract(poolContract, poolABI, signer),
  };
  contracts['usdtLp'] = {
    address: UsdtLp,
    contract: new ethers.Contract(UsdtLp, usdtLpABI, signer),
  };
  contracts['UsdtHEthLp'] = {
    address: UsdtHEthLp,
    contract: new ethers.Contract(UsdtHEthLp, usdtLpABI, signer),
  };
  contracts['usdtHtbcLp'] = {
    address: UsdtHBTCLp,
    contract: new ethers.Contract(UsdtHBTCLp, usdtLpABI, signer),
  };
  contracts['UsdtHLtcLp'] ={
    address: UsdtHLtcLp,
    contract: new ethers.Contract(UsdtHLtcLp, usdtLpABI, signer),
  }
  contracts['Wcmdhulp'] ={
    address: Wcmdhulp,
    contract: new ethers.Contract(Wcmdhulp, usdtLpABI, signer),
  }
  contracts['Wcmdhblp'] ={
    address: Wcmdhblp,
    contract: new ethers.Contract(Wcmdhblp, usdtLpABI, signer),
  }
  contracts['Wcmdhelp'] ={
    address: Wcmdhelp,
    contract: new ethers.Contract(Wcmdhelp, usdtLpABI, signer),
  }
  contracts['Wcmdhllp'] ={
    address: Wcmdhllp,
    contract: new ethers.Contract(Wcmdhllp, usdtLpABI, signer),
  }

  // contracts[]

  mining.forEach(({ id, uniTokenContractAddress,save,has,name,icon,buyUrl,supportUrl, name2,buyName }) => {
    const uniTokenContract = new ethers.Contract(uniTokenContractAddress, erc20ABI, signer);
    contracts[id] = {
      id,save,has,name,icon,buyUrl,supportUrl, name2,buyName,
      uniTokenContractAddress,
      uniTokenContract,
    };
  });
  return contracts;
}

function connectWallet(noMetaMaskCallback: () => void) {
  if (typeof ethereum === 'undefined') {
    noMetaMaskCallback();
    return;
  }

  ethereum.request({ method: 'eth_requestAccounts' })
    .catch((err: any) => {
      console.log('eth_requestAccounts:', err);
    });
}

function useWallet() {
  const [account, setAccount] = useState<null | string>(null);
  const [contracts, setContracts] = useState<any>(null);
  const [myProviders, setProviders] = useState<any>(null);

  useEffect(() => {
    if (typeof ethereum === 'undefined') {
      return;
    }

    const provider = new ethers.providers.Web3Provider(ethereum);

    const accountsChanged = (accounts: Array<string>) => {
      console.log(accounts)
      const account = accounts.length ? accounts[0] : null;
      setAccount(account);
      setProviders(provider);
      if (account) {
        const signer = provider.getSigner(account);
        setContracts(loadContracts(signer));
      } else {
        setContracts(null);
      }
    };

    if (typeof ethereum.isMetaMask === 'undefined' || typeof ethereum.off === 'undefined') {
      // 认为是手机 APP 里面的浏览器，需要以下操作
      ethereum.request({ method: 'eth_requestAccounts' })
        .then(accountsChanged)
        // .then((account: string) => accountsChanged([account]))
        .catch((err: any) => {
          console.log('eth_requestAccounts:', err);
        });
    }

    // 手机端一些 APP 里面浏览器：如果之前已经授权，则重新打开页面不会触发此事件
    ethereum.on('accountsChanged', accountsChanged);

    return () => {
      if (typeof ethereum.off === 'function') {
        ethereum.off('accountsChanged', accountsChanged);
      }
    };
  }, []);

  return [account, contracts,myProviders];
}

export { connectWallet, useWallet };
