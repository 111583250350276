import React from 'react';

import {
  Box,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { Props } from '../helpers';


const zhContent = (
  <>
    <p>
      <b>1.矿池总量：</b>本次芥末（WAS) 挖矿共有4.6万枚，其中8000枚用于早期UNISwap流动资金池，其余46000个WAS分配给4个流动挖矿池。
</p>
    <p>
      <b>2.矿池开采周期：</b>开采周期分为6个月，每次开采按月依次释放，依次递减。第一个月30%、第二个月25%、第三个月20%、第四个月15%、第五个月5%。
</p>
    <p>
      <b>3.开采矿池以及分配数量：</b>矿池V1（12000枚WAS）、 矿池V2（8000枚WAS）、 矿池V3（18000枚WAS）、矿池V4（8000枚WAS）。
<br />
      <b>矿池V1：</b>V1为投入比例的LTT和Curry代币，按矿池开采周期依次分别产出12000枚WAS。
<br />
      <b>矿池V2：</b>V2为投入比例的SBC和Curry代币，按矿池开采周期依次分别产出8000枚WAS。
<br />
      <b>矿池V3：</b>V3为投入比例的ETH和WAS代币，按矿池开采周期依次分别产出18000枚WAS。
<br />
      <b>矿池V4：</b>V4为投入比例的ETH和LTT代币，按矿池开采周期依次分别产出8000枚WAS。
</p>
    <p>
      <b>4.WAS全部释放条件以及销毁条件：</b>
      <br />
若要获得开采周期内V1和V2矿池释放的所有WAS，则矿池内Curry的数量需要≥3000枚，
超过3000枚Curry则采用加权平分的方式来分配。
</p>
    <p>
      若要获得开采周期内V3和V4矿池释放的所有WAS，则矿池内需要ETH的数量≥200枚。超过200枚ETH则采用加权平分的方式来分配。
</p>
    <p>
      销毁条件：以上四个矿池若没有超过相应的200枚Curry或ETH，则全部采用加权的方式进行实时销毁通缩。
</p>
    <p>
      <b>5.用户奖励说明</b>
      <br />
Curry作为新一代去中心化金融协议，近期进行品牌的应用升级，与芥末（WAS) 新一代Defi跨链聚合器平台进行新的聚合金融落地应用生态合作，共同推出用户持仓Curry奖励WAS的两大方式。同时，WAS开采量的10%由二级提成机制和红利矿池分发机制进行分发，两种结合机制将10%利润的分成分为三种情况：
</p>
    <p>
      <b>两大持仓奖励方式：</b>
      <br />
      A、当用户账户持有≥50Curry可享有二级推荐带来10%的WAS收益。
<br />
B、当用户账户持有≥500Curry可进入红利矿池享有加权分红。
</p>
    <p>
      <b>三种利润分红情况：</b>
      <br />
A、当矿工有1级和2级推荐人时，开采量的10%贡献给1级和2级推荐矿工。
<br />
B、当矿工有1级推荐人无2级推荐人时，5%给推荐矿工，5%进入红利矿池。
<br />
C、当矿工没人推荐进入挖矿时，10%进入红利矿池。
</p>
    <p style={{wordWrap: 'break-word'}}>
      <b>7.获取Curry的渠道：</b>
      <br />
A、可通过官方合约地址进入Uniswap进行ETH/Curry兑换
<br />
合约地址：0x89494C685E06d18ff0c65F9e756976EdAa6E806a
<br />
B、 可通过官方网站进入Dapp进行挖矿产出Curry
<br />
官网地址：<a href="http://www.curry.city/" target="_blank" rel="noreferrer">http://www.curry.city/</a>
</p>
  </>
);

const enContent = (
  <>
    <p>
      WAS is a new generation of Defi cross-chain aggregator platform that realizes multi-currency pledge and lending. The platform aggregates and supports ERC20 multiple ecological currency pledges and loans, with a total of 54,000 WAS, pre-mined 8,000 WAS for early UNISwap liquidity Pool, 46,000 WAS are used for liquidity mining, and the corresponding proportion of WAS is allocated according to the share of Defi users who provide liquidity for each pool. WAS will be mined in the next 6 months.    </p>
    <h4 style={{ color: '#4e3646' }}>1. Mining plan:</h4>
    <p>
      46000 WAS total supply. 8000 WAS is for pre-mining, so that it can provide liquidity for pool V3. Distributing 46000 WAS to four pools. Supply 12000 WAS to V1. Supply 8000 WAS to V2. Supply 18000 WAS to V3. Supply 8000 WAS to V4. Total of 6 months of mining. The first month of mining supply 30% of the supply. The second month of mining supply 25% of the supply. The third month of mining supply 20% of the supply. The fourth month of mining supply 15% of the supply. The fifth month of mining supply 5% of the supply. The sixth month of mining supply 5% of the supply. 10% of mining will be distributed by the model of bonus mining pool and recommendation mechanism. These two combined mechanisms divide the 10% profit into three situations: when miners have two referrals, 10% of the mining returns are contributed to these two recommended miners. When a miner only has one referral, 5% will be given to the recommended miner and 5% will enter into the bonus mining pool. When new miners have no referral, 10% returns will enter into the bonus mining pool. Miners holding more than 500 Curry can enter the bonus mining pool and enjoy weighted dividends.    </p>
    <h4 style={{ color: '#4e3646' }}>2. V1 Pool</h4>
    <p>
      Liquidity mining total supply 12000 WAS to V1. V1 pool is for LTT and Curry. The mining pool has a six-month mining cycle, and the output decreases every month. The release rate of WAS is in seconds. The first month of mining supply 30% of the supply. The second month of mining supply 25% of the supply. The third month of mining supply 20% of the supply. The fourth month of mining supply 15% of the supply. The fifth month of mining supply 5% of the supply. The sixth month of mining supply 5% of the supply. When the total value of LTT and Curry in the pool is greater than or equal to 200 ETH, all WAS will be released as planned. Otherwise, the WAS mining release in this cycle follows the proportional mining mechanism. LTT and Curry use UNISwap exchange rate for ETH equivalent conversion.    </p>
    <h4 style={{ color: '#4e3646' }}>3. V2 Pool</h4>
    <p>
      Liquidity mining total supply 8000 WAS to V2. V pool is for SBC and Curry. The mining pool has a six-month mining cycle, and the output decreases every month. The release rate of WAS is in seconds. The first month of mining supply 30% of the supply. The second month of mining supply 25% of the supply. The third month of mining supply 20% of the supply. The fourth month of mining supply 15% of the supply. The fifth month of mining supply 5% of the supply. The sixth month of mining supply 5% of the supply. When the total value of SBC and Curry in the pool is greater than or equal to 200 ETH, all WAS will be released as planned. Otherwise, the WAS mining release in this cycle follows the proportional mining mechanism. SBC and Curry use UNISwap exchange rate for ETH equivalent conversion.    </p>
    <h4 style={{ color: '#4e3646' }}>4. V3 Pool</h4>
    <p>
      Liquidity mining total supply 18000 WAS to V3. V3 pool is for ETH and LTT. The mining pool has a six-month mining cycle, and the output decreases every month. The release rate of WAS is in seconds. The first month of mining supply 30% of the supply. The second month of mining supply 25% of the supply. The third month of mining supply 20% of the supply. The fourth month of mining supply 15% of the supply. The fifth month of mining supply 5% of the supply. The sixth month of mining supply 5% of the supply. When more than 200 ETH in the pool, all WAS will be released as planned. Otherwise, the WAS mining release in this cycle follows the proportional mining mechanism.     </p>
    <h4 style={{ color: '#4e3646' }}>5. V4 Pool</h4>
    <p>
      Liquidity mining total supply 8000 WAS to V4. V4 pool is for LTT and ETH. The mining pool has a six-month mining cycle, and the output decreases every month. The release rate of WAS is in seconds. The first month of mining supply 30% of the supply. The second month of mining supply 25% of the supply. The third month of mining supply 20% of the supply. The fourth month of mining supply 15% of the supply. The fifth month of mining supply 5% of the supply. The sixth month of mining supply 5% of the supply. When more than 200 ETH in the pool , all WAS will be released as planned. Otherwise, the WAS mining release in this cycle follows the proportional mining mechanism.    </p>
  </>
);



const l10n: { [key: string]: { [key: string]: any } } = {
  'EN': {
    title: 'Rule description',
    content: enContent,
    look:"Look forward to it...",
  },
  '中文': {
    title: '规则说明',
    look:"尽情期待...",
    content: zhContent,
  }
};

const useStyles = makeStyles({
  title: {
    fontSize: '120%',
    fontWeight: 'bold',
    marginTop: 30,
    marginBottom: 30
  },
  t1: {
    fontSize: 16,
    marginTop:50,
    textAlign:"center",
    color: '#FFFFFF',
  },
  content: {
    border: '1px solid #efc6ed',
    color: '#768c76',
    backgroundColor: 'white',
    padding: "14px 24px",
    borderRadius: 20,
    fontSize: '90%',
    textAlign: 'left'
  }
});

function Page({ lang }: Props) {
  const styles = useStyles();

  return (
    <Box style={{ textAlign: 'center' }}>

<Box className={styles.t1}>{l10n[lang].look}</Box>
    </Box>
  );
}

export default Page;