import { BigNumber, utils } from 'ethers';

export interface Props {
  lang: string;
  account?: string;
  contracts?: any;
  myProviders?:any;
};

export const miningType = [
  {
    id: "1", // 在矿池合约中的序号
    tips: '8日9:00（GMT-4)',
    icon: '/icons/pic_1.png',
    nameEN:'Single Pledge',
    nameCN: '单质押挖矿'

  },
  {
    id: "2",
    tips: '9日13:14（GMT-4)',
    icon: '/icons/2.png',
    nameEN:'Liquidity Pledge',
    nameCN: '流动性挖矿'
  },
  {
    id: "3",
    tips: '10日13:14（GMT-4)',
    icon: '/icons/3.png',
    nameEN:'Recommended revenue',
    nameCN: '推荐收益'
  },

];



export const mining = [
  {
    id: "1", // 在矿池合约中的序号
    type: '1',
    icon: '/icons/WCMD.png',
    name: 'HWCMDPRO ',
    save:"HWCMDPRO",
    buyUrl:"https://ht.mdex.me/#/swap?inputCurrency=0x25F0c9b5Dda7F1630Efa992e080BDe5128824249",
    has:"HWCMDPRO",
    uniTokenContractAddress: '0x25F0c9b5Dda7F1630Efa992e080BDe5128824249',
  },
  {
    id: "2",
    type: '1',
    icon: '/icons/HBTC.png',
    name: 'HBTC',
    save:"HBTC",
    buyUrl:"https://ht.mdex.me/#/swap?inputCurrency=0x66a79D23E58475D2738179Ca52cd0b41d73f0BEa",
    has:"HWCMDPRO",
    uniTokenContractAddress: '0x66a79D23E58475D2738179Ca52cd0b41d73f0BEa',
  },
  {
    id: "3",
    type: '1',
    icon: '/icons/ETH.png',
    name: 'HETH',
    buyUrl:"https://ht.mdex.me/#/swap?inputCurrency=0x64FF637fB478863B7468bc97D30a5bF3A428a1fD",
    save:"HETH",
    has:"HWCMDPRO",
    uniTokenContractAddress: '0x64FF637fB478863B7468bc97D30a5bF3A428a1fD',
  },
  {
    id: "4",
    type: '1',
    icon: '/icons/ltc2.png',
    buyUrl:"https://ht.mdex.me/#/swap?inputCurrency=0xecb56cf772B5c9A6907FB7d32387Da2fCbfB63b4",
    name: 'HLTC',
    save:"HLTC",
    has:"HWCMDPRO",
    uniTokenContractAddress: '0xecb56cf772B5c9A6907FB7d32387Da2fCbfB63b4',
  },
  {
    id: "5",
    type: '2',
    icon: '/icons/WCMD-usdt.png',
    buyUrl:"https://ht.mdex.me/#/swap?inputCurrency=0x25F0c9b5Dda7F1630Efa992e080BDe5128824249",
    supportUrl: 'https://ht.mdex.me/#/add/0x25F0c9b5Dda7F1630Efa992e080BDe5128824249/0xa71EdC38d189767582C38A3145b5873052c3e47a',
    name: 'USDT',
    name2: 'HWCMDPRO/USDT',
    // save:"HFIL",
    save:"USDT_HWCMDPRO_LP",
    has:"HWCMDPRO",
    uniTokenContractAddress: '0x43E534fa0BF38d2823232f377D8343Aa7a622804',
  },
  {
    id: "6",
    type: '2',
    icon: '/icons/WCMD-btc.png',
    buyUrl:"https://ht.mdex.me/#/swap?inputCurrency=0x66a79D23E58475D2738179Ca52cd0b41d73f0BEa",
    supportUrl: 'https://ht.mdex.me/#/add/0x25F0c9b5Dda7F1630Efa992e080BDe5128824249/0x66a79D23E58475D2738179Ca52cd0b41d73f0BEa',
    name: 'HBTC',
    name2: 'HWCMDPRO/HBTC',
    buyName:"USDT",
    save:"HBTC_HWCMDPRO_LP",
    has:"HWCMDPRO",
    // 0x43E534fa0BF38d2823232f377D8343Aa7a622804
    uniTokenContractAddress: '0xA71ef820a566Aa156dd91A51Bf5c1f5e3De164A5',
  },
  {
    id: "7",
    type: '2',
    icon: '/icons/WCMD-eth.png',
    buyUrl:"https://ht.mdex.me/#/swap?inputCurrency=0x64FF637fB478863B7468bc97D30a5bF3A428a1fD",
    supportUrl: 'https://ht.mdex.me/#/add/0x25F0c9b5Dda7F1630Efa992e080BDe5128824249/0x64FF637fB478863B7468bc97D30a5bF3A428a1fD',
    name: 'HETH',
    name2: 'HWCMDPRO/HETH',
    buyName:"HETH",
    save:"HETH_HWCMDPRO_LP",
    has:"HWCMDPRO",
    // 0x43E534fa0BF38d2823232f377D8343Aa7a622804
    uniTokenContractAddress: '0x5886b49D84E8eCB17d4dB6580051467C7F925aa4',
  },
  {
    id: "8",
    type: '2',
    icon: '/icons/WCMD-ltc.png',
    buyUrl:"https://ht.mdex.me/#/swap?inputCurrency=0xecb56cf772B5c9A6907FB7d32387Da2fCbfB63b4",
    supportUrl: 'https://ht.mdex.me/#/add/0x25F0c9b5Dda7F1630Efa992e080BDe5128824249/0xecb56cf772B5c9A6907FB7d32387Da2fCbfB63b4',
    name: 'HLTC',
    name2: 'HWCMDPRO/HLTC',
    buyName:"HLTC",
    save:"HLTC_HWCMDPRO_LP",
    has:"HWCMDPRO",
    // 0x43E534fa0BF38d2823232f377D8343Aa7a622804
    uniTokenContractAddress: '0x624DaabaebEf9B185996e4a4C6fCFDcA6c21Da67',
  },
  // {
  //   id: "6",
  //   type: '1',
  //   icon: '/icons/USDT.png',
  //   buyUrl:"https://ht.mdex.com/#/swap?inputCurrency=0xa71EdC38d189767582C38A3145b5873052c3e47a",
  //   save:"HBTC",
  //   has:"WCMDPRO",
  //   name: 'Lighting USDT',
  //   uniTokenContractAddress: '0xa71EdC38d189767582C38A3145b5873052c3e47a',
  // },
  // {
  //   id: "7",
  //   type: '1',
  //   icon: '/icons/MDX.png',
  //   buyUrl:"https://ht.mdex.com/#/swap?inputCurrency=0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c",
  //   name: 'Lighting MDX',
  //   save:"MDX",
  //   has:"WCMDPRO",
  //   uniTokenContractAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
  // },
  // {
  //   id: "8",
  //   type: '2',
  //   icon: '/icons/WCMD-usdt.png',
  //   buyUrl:"https://ht.mdex.com/#/swap?inputCurrency=0xa71EdC38d189767582C38A3145b5873052c3e47a",
  //   supportUrl: 'https://ht.mdex.com/#/add/0x25F0c9b5Dda7F1630Efa992e080BDe5128824249/0xa71EdC38d189767582C38A3145b5873052c3e47a',
  //   name: 'Lighting USDT',
  //   name2: 'WCMDPRO/USDT',
  //   buyName:"USDT",
  //   save:"USDT_WCMDPRO_LP",
  //   has:"WCMDPRO",
  //   // 0x43E534fa0BF38d2823232f377D8343Aa7a622804
  //   uniTokenContractAddress: '0x0858AbfF11Dd2C59792c43dCb43FaAC730Ce4B4C',
  // },
  // {
  //   id: "9",
  //   type: '2',
  //   icon: '/icons/WCMD-ht.png',
  //   buyUrl:"https://ht.mdex.com/#/swap?inputCurrency=ht",
  //   supportUrl: 'https://ht.mdex.com/#/add/0x0892FE7cb2b89E05eF852c2d5e685042CaC0Df66/ht',
  //   name: 'Lighting HT',
  //   name2: 'WCMDPRO/HT',
  //   buyName:"HT",
  //   save:"HT_WCMDPRO_LP",
  //   has:"WCMDPRO",
  //   uniTokenContractAddress: '0x8D54D745dCD02BF88C05bA77c68659eA5D8b64DA',
  // },
  // {
  //   id: "10",
  //   type: '2',
  //   icon: '/icons/WCMD-btc.png',
  //   buyUrl:"https://ht.mdex.com/#/swap?inputCurrency=0x66a79D23E58475D2738179Ca52cd0b41d73f0BEa",
  //   supportUrl: 'https://ht.mdex.com/#/add/0x0892FE7cb2b89E05eF852c2d5e685042CaC0Df66/0x66a79D23E58475D2738179Ca52cd0b41d73f0BEa',
  //   name: 'Lighting HBTC',
  //   name2: 'WCMDPRO/HBTC',
  //   buyName:"HBTC",
  //   save:"HBTC_WCMDPRO_LP",
  //   has:"WCMDPRO",
  //   uniTokenContractAddress: '0x6f69FB784C050e9b5bD8B9891c95DD6607C787A8',
  // },

];
export function daojiShi(storptimes: string) {
  var newtime = new Date() //获取当前时间
  var storptime = new Date(storptimes) //获取截止时间

  var mistiming = storptime.getTime() - newtime.getTime() //   获取截止时间距离现在的毫秒差

  var days: string | number = Math.floor(mistiming / 1000 / 60 / 60 / 24); //获取天数
  var hours: string | number = Math.floor(mistiming / 1000 / 60 / 60 % 24); // 获取小时
  var minutes: string | number = Math.floor(mistiming / 1000 / 60 % 60); //获取分钟数
  var seconds: string | number = Math.floor(mistiming / 1000 % 60); //获取秒数

  // 判断天、时、分、秒是不是两位数，如果是直接输出，如果不是在前边加个0;
  if (days < 10) { days = "0" + days; }
  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }

  // 第一种连接方法
  // var rels = days + "天" + hours + "时" + minutes + "分" + seconds + "秒";
  // 第二种连接方法
  var rels = `${days}天${hours}时${minutes}分${seconds}秒`

  // 判断时间差是不是正数，就是截止时间是不是比现在的时间晚
  var mis = mistiming > 0 ? rels : "请输入正确的时间"
  return mis
};

export function round(s: string, decimal: number) {
  const i = s.indexOf('.');

  if (i !== -1) {
    s = s.substring(0, i + 1 + decimal);
  }

  return s;
};

export function roundEther(n: BigNumber, decimal: number) {
  // console.log(n)
  return round(utils.formatEther(n), decimal);
};

export function gt_0(a: string) {
  const x = Number.parseFloat(a);
  return !Number.isNaN(x) && x > 0;
};

export function calcIdeaRewards() {
  const started = 1604855549;
  const elapsed = (new Date()).getTime() / 1000 - started;

  if (elapsed >= 12960000) {
    // 大于 5 个月
    return 46000;
  }
  if (elapsed >= 10368000) {
    // 大于 4 个月
    return 43700 + (elapsed - 10368000) * 2300 / 2592000;
  }
  if (elapsed >= 7776000) {
    // 大于 3 个月
    return 34500 + (elapsed - 7776000) * 6900 / 2592000;
  }
  if (elapsed >= 5184000) {
    // 大于 2 个月
    return 25300 + (elapsed - 5184000) * 9200 / 2592000;
  }
  if (elapsed >= 2592000) {
    // 大于 1 个月
    return 13800 + (elapsed - 2592000) * 11500 / 2592000;
  }

  return elapsed * 13800 / 2592000;
};
