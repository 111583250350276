import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { BigNumber, utils } from 'ethers';

import {
  Avatar,
  Box,
  Button,
  Grid,
  Container,
  Snackbar,
} from '@material-ui/core';

import banner from '../assets/inviter_cn.png';
import bannerEN from '../assets/inviter_en.png';
import WCMD from '../assets/WCMD.png';
import { makeStyles } from '@material-ui/core/styles';

import { Props, roundEther, gt_0 } from '../helpers';



const l10n: { [key: string]: { [key: string]: any } } = {
  'EN': {
    deposit: 'deposit',
    amount: 'amount',
    earn: 'earn',
    choose: 'choose',
    earned: 'To be harvested',
    harvest: 'harvest',
    staked: 'Deposited',
    approve: 'Approve',
    withdraw: 'Redeem',
    available: 'Available balance',
    availableWithdraw: 'Available redeem',
    exit: 'Harvest and extract',
    referrer: 'Referrer address (must, only once)',
    addressInvalid: 'address invalid',
    txSent: 'Transaction sent.',
    totalText: 'Current pool pledge total',
    buyText: 'Buy',
    harvestBut: 'One click harvest',poolText:"Ore pool",
    provide:"Provide liquidity"

  },
  '中文': {
    deposit: '存入',
    amount: '金额',
    earn: '赚取',
    choose: '选择',
    earned: '待收获的',
    harvest: '收获',
    harvestBut: '一键收获',
    staked: '存入的',
    approve: '授权',
    withdraw: '赎回',
    available: '可用余额',
    availableWithdraw: '可赎回',
    exit: '收获并提取',
    referrer: '推荐人地址（必填，只有一次填写机会）',
    addressInvalid: '地址无效',
    txSent: '交易已发送。',
    totalText: '当前矿池质押总',
    buyText: '购买',poolText:"矿池",
    provide:"提供流动性"
  }
};

const useStyles = makeStyles({
  title: {
    fontSize: '120%',
    fontWeight: 'bold',
    marginTop: 30,
    marginBottom: 30,
    color: '#4c3444'
  },
  card: {
    background: "#17141A",
    borderRadius: 20,
    color: '#768b76',
    marginBottom: 2,
    width: "100%",
    paddingTop: 40,
    paddingBottom: 40,
  },
  iconCtn: {
    fontSize: 36,
    height: 100,
    width: 100,
    borderRadius: 50,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto 16px',
  },
  avatar: {
    backgroundColor: 'transparent',
    height: 80,
    width: 80,
    fontSize: '110%'
  },
  t1: {
    color: '#737276',
    fontSize: '140%',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  t2: {
    fontSize: '90%',
    color: '#737276',

  },
  t3: {
    fontSize: '130%',
    color: '#ffffff',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  t4: {
    fontSize: '130%',
    color: '#09E2D5',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  btn: {
    backgroundColor: '#143D3F',
    display: 'block',
    textDecoration: 'none',
    color: '#09E2D5',
    fontSize: '110%',

    lineHeight: 2,
    borderRadius: 14,

  },
  btn2: {
    backgroundColor: '#4C1F3D',
    display: 'block',
    textDecoration: 'none',
    color: '#F222A1',
    fontSize: '110%',

    lineHeight: 2,
    borderRadius: 14,

  },
  inline:{
    width:"80%",
    display:'flex',
    justifyContent:"space-between"
  },
  inlineBtn: {
    margin: '0 6px',
    backgroundColor: '#143D3F',
    textDecoration: 'none',
    color: '#09E2D5',
    width:'50%',
    fontSize: '100%',

    lineHeight: 2.3,
    borderRadius: 30,
  },

  inlineBtn2: {
    margin: '0 6px',
    backgroundColor: '#452B14',
    textDecoration: 'none',
    color: '#FF8900',
    width:'50%',
    fontSize: '100%',

    lineHeight: 2.3,
    borderRadius: 30,
  },

  iconCtn2: {
    fontSize: 36,
    height: 90,
    width: 150,
    borderRadius: 50,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto 16px',
  },
  avatar2: {
    backgroundColor: 'transparent',
    height: 90,
    width: 150,
    fontSize: '110%'
  },
});

function Page({ lang, account, contracts }: Props) {
  const styles = useStyles();
  const [snackbar, setSnackbar] = useState(false);

  const [referrer, setReferrer] = useState(BigNumber.from(0));

  useEffect(() => {
    const work = () => {
      if (!account || !contracts) {
        return;
      }

      contracts.pool.contract.userInfo(account)
      .then((res: any) => {
        setReferrer(res.referedRewards)
      })
      .catch((err: any) => {
        console.error('userInfo:  z',err.message)
      });

    };

    work();

    const t = setInterval(work, 20000);

    return () => clearInterval(t);
  }, [account, contracts]);







  const onExit = () => {
    // 提取全部
    contracts.pool.contract.getReferedRewards()
    .then(() => {
      setSnackbar(true);
    })
    .catch((err: any) => {
      console.error('exit: ', err.message)
    });


  };

  return (
    <Box style={{ textAlign: 'center' }}>
      <Box>
        <img src={lang === 'EN' ? bannerEN : banner} alt="banner" width="100%" />
      </Box>
      <Container>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12}>
            <Box className={styles.card}>
              <Box className={styles.iconCtn}>
                <Avatar className={styles.avatar} src={WCMD} />
              </Box>
              <Box className={styles.t2}>{l10n[lang].earned} WCMDPRO</Box>
              <Box className={styles.t4} style={{ marginBottom: 30 }}>{roundEther(referrer, 6)}</Box>
              <Box style={{ margin: '0 30px' }}>
                <Button
                  fullWidth
                  className={styles.btn}
                  disabled={referrer.eq(0)}
                  onClick={onExit}
                >{l10n[lang].harvestBut}</Button>
              </Box>
            </Box>
          </Grid>
        </Grid>


      </Container>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackbar}
        autoHideDuration={2000}
        message={l10n[lang].txSent}
        onClose={(event: any, reason?: string) => {
          if (reason === 'clickaway') {
            return;
          }
          setSnackbar(false);
        }}
      />

    </Box>
  );
}

export default Page;
