import React, {useEffect, useState} from 'react';
import {BigNumber} from 'ethers';
import axios from 'axios'
import cookie from 'react-cookies'

import {
    Avatar,
    Box,
    Button,
    Grid,
    Container,
    Snackbar,
} from '@material-ui/core';

import {makeStyles} from '@material-ui/core/styles';

import logo from '../assets/logo.png';
import {mining, Props, roundEther} from '../helpers';
import {Link} from "react-router-dom";


const l10n: { [key: string]: { [key: string]: any } } = {
    'EN': {
        toDistribute: 'Undistributed Bonus',
        declaredCurry: 'Curry declared',
        canDeclare: 'Curry remain to declare',
        bonus: 'My Bonus',
        involve: 'Declare',
        claim: 'Claim',
        txSent: 'Transaction sent.',
        look: "Look forward to it...",
        back: "Back"
    },
    '中文': {
        toDistribute: '当前周期待瓜分',
        declaredCurry: '当前周期已声明 Curry 量',
        canDeclare: '我可参与声明的 Curry 量',
        bonus: '我可以领取的红利（累计）',
        involve: '参与当期声明',
        claim: '领取',
        txSent: '交易已发送。',
        look: "尽情期待...",
        back: "返回"
    }
};

const useStyles = makeStyles({
    title: {
        fontSize: '120%',
        fontWeight: 'bold',
        marginTop: 30,
        marginBottom: 30,
        color: '#4C3444'
    },
    card: {
        border: '1px solid #efc6ed',
        borderRadius: 20,
        color: '#768B76',
        marginBottom: 2,
        paddingTop: 40,
        paddingBottom: 40,
    },
    iconCtn: {
        backgroundColor: '#F0E7EA',
        fontSize: 36,
        height: 100,
        width: 100,
        borderRadius: 50,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        boxShadow: 'inset 4px 4px 8px #e2cfd5,inset -6px -6px 12px #f7f2f4',
        margin: '0 auto 16px',
    },
    avatar: {
        backgroundColor: 'transparent',
        height: 80,
        width: 80,
        fontSize: '110%'
    },
    t1: {
        fontSize: 16,
        marginTop: 50,
        textAlign: "center",
        color: '#FFFFFF',
    },
    t2: {
        fontSize: '90%',
        color: '#5B2639',
        marginBottom: 20,
    },
    btn: {
        backgroundColor: '#E3D7E2',
        display: 'block',
        textDecoration: 'none',
        color: '#000000',
        fontSize: '110%',
        fontWeight: 'bold',
        lineHeight: 3,
        borderRadius: 14,
        boxShadow: '6px 6px 12px #abc6ab,-12px -12px 24px -2px #d7e6d7'
    },
    inlineBtn: {
        margin: '0 6px',
        backgroundColor: '#E3D7E2',
        textDecoration: 'none',
        color: '#000000',
        fontSize: '110%',
        fontWeight: 'bold',
        lineHeight: 3,
        borderRadius: 14,
        boxShadow: '6px 6px 12px #abc6ab,-12px -12px 24px -2px #d7e6d7'
    },
    notice: {
        color: '#FFFFFF',
        textAlign: 'left',
        padding: 25,
        borderBottom: '1px solid #777777',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between'
    },
    modal: {
        // height:1000,
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#221F24',
        color: '#FFFFFF',
        textAlign: 'left',
        padding: '40px 30px',
        overflow:"auto"
    },
    modalTitle: {
        textAlign: 'center',
        fontSize: 36,
        color: '#09E2D5'
    },
    modalText: {
        overflow:"auto",
        // overflowY:"visible",
        textAlign: 'left',
        margin: '60px auto',
        maxWidth: '800px'
    },
    modalBack: {
        cursor: 'pointer'
    }
});
function date2WeekDay (date: Date) {
    var weekDay = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
    return weekDay[date.getDay()]
}

function dateFormat (timeStamp: string | number | Date, format: string) {
    let date = new Date(timeStamp)
    var dateObj: any = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
        'q+': Math.floor((date.getMonth() + 3) / 3),
        'S+': date.getMilliseconds(),
        'w+': date2WeekDay(date)
    }
    if (/(y+)/i.test(format)) {
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (var k in dateObj) {
        if (new RegExp('(' + k + ')').test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? dateObj[k] : ('00' + dateObj[k]).substr(('' + dateObj[k]).length))
        }
    }
    return format
}


function Page({lang, account, contracts}: Props) {
    const styles = useStyles();
    const [ENlist, setENList] = useState([] as any[]);
    const [CNlist, setCNList] = useState([] as any[]);
    const [notice, setNotice] = useState({} as any)
    const [showModal, setShowModal] = useState(false);


    const goDetail: any = async (id: number) => {

        setShowModal(true)
        const res = await axios.get('https://notice.wcmd.pro:442/publicApi/getNotificationById?id=' + id)
        if (res.data.status === 0) {
            cookie.save("notice-"+id,""+id,{path:"/"})
            setNotice(res.data.data)
            return
        }
    }
    const getList = async () => {
        const res = await axios.get('https://notice.wcmd.pro:442/publicApi/getAllNotification', {
            headers: {
                'Accept-Language': 'zh_cn'
            }
        })
        if (res.data.status === 0 && CNlist.length === 0) {
            setCNList(res.data.data)
        }
        const res2 = await axios.get('https://notice.wcmd.pro:442/publicApi/getAllNotification', {
            headers: {
                'Accept-Language': 'en_us'
            }
        })
        if (res2.data.status === 0 && ENlist.length === 0) {
            setENList(res2.data.data)
        }
    }

    let modal
    if (showModal) {
        modal =
            <div className={styles.modal}>
                <div className={styles.modalBack} onClick={() => {
                    setShowModal(false)
                }}>&lt;&lt;{l10n[lang].back}
                </div>
                <div className={styles.modalTitle}>{lang === 'EN' ? notice.engTitle : notice.cnTitle}</div>
                <div className={styles.modalText} dangerouslySetInnerHTML={{
                    __html: lang === 'EN' ? notice.engText : notice.cnText
                }}></div>
            </div>
    }
    const list = lang === 'EN' ? ENlist : CNlist
    useEffect(() => {
        getList()
    }, [])

    return (
        <Box style={{textAlign: 'center', marginTop: 50, overflow:'visiable'}}>  {
            list && list.length && list.map(item => (
                item && <div key={item.id} className={styles.notice} onClick={() => {
                    goDetail(item.id)
                }}>{item.title}
                    <div>{item && item.create && item.create.slice(0, 10)}</div>
                </div>
            ))
        } {modal} </Box>
    );
}

export default Page;
