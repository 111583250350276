export  default {
    item: {
        width: '100%',
            height: 'auto',
            margin: 'auto',
            display: 'block',
            backgroundColor: 'rgb(237,237,237)',
            // marginTop: '2%',
            borderRadius: 10,
            color: '#000000',
            overflow: 'hidden',
            position: 'relative',
            marginTop: '10px',
            boxShadow:'0.3em 0.3em 1em rgba(41, 42, 68, 0.3)',
            textAlign: 'center',
            fontSize: '14px'
    },

    status: {
        transformOrigin: 'center',
        width: '50%',
        display: "block",
        padding: '5% 0 2% 0',
        transform: 'rotate(45deg)',
        textAlign: 'center',
        float: 'right',
        // top:'-47px',
        marginRight:'-20%',
        marginTop: '-1%',
        boxShadow:'0.2em 0.2em 0.2em rgba(0,0,0,0.3)',
        fontsize: '10px !important'
    },
    statusSpan: {
        fontSize:'11px'
    },

    itemItem:{
        width: '90%',
            height: '110px',
            borderRadius: '10px',
            boxShadow:'0.3em 0.3em 1em rgba(41, 42, 68, 0.3)',
            padding: '3%',
            // boxShadow:'0.3em 0.3em 0.3em rgba(0,0,0,0.2)'
            margin: '2% 2%',
            float: 'left',
            color: "#464545"
    },
    itemItemIcon: {
      marginTop: '5%'
    },
    icon: {
        width: '100%',
            height: '100%'
    },

    leftTitle: {
        padding: '0',
            margin: '0',
            lineHeight: '30px',
            width: '40%',
            float: 'left',
            listStyleType: 'none',
            fontsize: '10px !important',
    },

    rightContent : {
        padding: '0',
            margin: '0',
            lineHeight: '30px',
            width: '58%',
            contentType: 'left',
            float: 'left',
            listStyleType: 'none',

    },

    rightContentLi: {
        textAlign: 'left',
            fontsize: '12px !important',
    },

    poolType:{
        textAlign: 'left',
            fontsize: '20px',
            fontWeight: 'bold',
            margin: '0 2%;',
            color: '#2d2c2c'
    },

    button: {
        boxShadow:'0.1em 0.1em 0.1em rgba(0,0,0,0.3)',
        backgroundColor: 'rgba(0, 210, 210, 0.26)',
        marginTop: '12%',
        color: 'rgba(70,69,69,0.91)'
    },

    returnButton: {
        boxShadow:'0.1em 0.1em 0.1em rgba(0,0,0,0.3)',
            backgroundColor: 'rgba(0, 210, 210, 0.26)',
            letterSpacing: '5px',
            marginTop: '2%',
            marginLeft: '0%',
            color: 'rgba(70,69,69,0.91)',
            padding: '5px 20px'
    },

    no: {
        padding: 0,
            margin: 0,
            listStyleType: 'none',
            lineHeight: '40px',
            textAlign: 'center',
    },

    addressEle:{
        float: 'left',
            fontsize: '14px'
    },

    middleLine: {
        display:"inline-block",
            width: '95%',
            height: '100%' ,
        borderBottom: '1px solid #8080802e',
        paddingBottom: '20px'
    },

    rankedTitle: {
        lineHeight: '40px',
        margin: 0,
        padding: 0,
        listStyleType: 'none',
        float: 'left',
        textAlign: 'left',
        color: '#484747',
        fontsize: '12px'
    },

    endDateStyle: {
        width: '100%',
        height: '30px',
        position: "relative",
        float: "right",
        textAlign: 'right',
        marginRight: '6%',
        fontSize: '10px'
    },
    isMine: {
        fontWeight: 'bold',
        color: 'blue',
        fontsize: '16px',
        float: 'left',

    }
}
