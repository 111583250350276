import rewardABI from './wasFarmerABI.json';
import hongliABI from './hongliABI.json';
// import poolABI from './poolABI.json';
import poolABI from './minePoolABI.json';
import usdtLpABI from './usdtLpABI.json';
import LpABI from './lpBAI.json';

// A Human-Readable ABI; any supported ABI format could be used
export const erc20ABI = [
    // Read-Only Functions
    "function totalSupply() external view returns (uint)",
    "function balanceOf(address owner) view returns (uint256)",
    "function decimals() view returns (uint8)",
    "function symbol() view returns (string)",
    "function allowance(address owner, address spender) view returns (uint256)",

    // Authenticated Functions
    "function transfer(address to, uint amount) returns (boolean)",
    "function approve(address spender, uint256 amount) external returns (bool)",

    // Events
    "event Transfer(address indexed from, address indexed to, uint amount)"
];

export const rewardABIBak = [
    // Read-Only Functions
    // 查看奖励
    "function viewReward(uint _pid,address _user) public view returns(uint rewardAmount,uint burntAmount)",
    // 查看用户各处的 Curry 总量
    "function checkUserPairTotalLpCurry(address _user)public view returns(uint)",
    // 查看总挖出，总销毁，红利矿池总奖励
    "function getData() public view returns(uint _wasMintTotal,uint _wasBurntTotal,uint _wasBonusTotal)",
    // 查看单个用户数据
    "function userData(address user)",

    // Authenticated Functions
    // 抵押
    "function depositLp(uint256 _pid, uint256 _amount,address _invite)",
    // 收割并赎回
    "function withdrawLp(uint _pid ,uint _amount)",
    // 提取奖励
    "function getReward(uint _pid)",

    // Events
];

export { rewardABI, hongliABI,poolABI,usdtLpABI,LpABI };
