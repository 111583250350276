import React, { useEffect, useState } from 'react';
import { BigNumber } from 'ethers';

import {
  Avatar,
  Box,
  Grid,
  Container,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { Props, miningType, roundEther } from '../helpers';


const l10n: { [key: string]: { [key: string]: any } } = {
  'EN': {
    staked: 'My pledge',
    totalStaked: 'Total Staked',
    mined: 'My mined',
    invited: 'My reward for inviting',
    look:"Look forward to it...",
  },
  '中文': {
    staked: '我的质押',
    totalStaked: '总质押',
    mined: '我可以获得的挖矿收益',
    invited: '我可以获得的推荐奖励',
    look:"尽情期待...",
  }
};

const useStyles = makeStyles({
  card: {
    border: '1px solid #efc6ed',
    borderRadius: 20,
    color: '#768b76',
    marginBottom: 2,
    padding: 20,
  },
  head: {
    display: 'flex',
    color: '#5b2639',
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    lineHeight: 2
  },
  avatar: {
    backgroundColor: 'transparent',
    fontSize: 30,
    marginRight: 8,
  },
  t1: {
    fontSize: 16,
    marginTop:50,
    textAlign:"center",
    color: '#FFFFFF',
  },
  t2: {
    fontSize: 30,
    fontWeight: 'bold',
    color: '#5b2639',
  },
});

// function useStats(account: any, contracts: any) {
//   // 我在各个矿池的质押
//   const [staked0, setStaked0] = useState(BigNumber.from(0));
//   const [staked1, setStaked1] = useState(BigNumber.from(0));
//   const [staked2, setStaked2] = useState(BigNumber.from(0));
//   const [staked3, setStaked3] = useState(BigNumber.from(0));

//   // 各个矿池的总质押
//   const [stakeda0, setStakeda0] = useState(BigNumber.from(0));
//   const [stakeda1, setStakeda1] = useState(BigNumber.from(0));
//   const [stakeda2, setStakeda2] = useState(BigNumber.from(0));
//   const [stakeda3, setStakeda3] = useState(BigNumber.from(0));

//   // 我在各个矿池的挖矿收益
//   const [mined0, setMined0] = useState(BigNumber.from(0));
//   const [mined1, setMined1] = useState(BigNumber.from(0));
//   const [mined2, setMined2] = useState(BigNumber.from(0));
//   const [mined3, setMined3] = useState(BigNumber.from(0));

//   // 我在各个矿池的推荐收益
//   const [promote0, setPromote0] = useState(BigNumber.from(0));
//   const [promote1, setPromote1] = useState(BigNumber.from(0));
//   const [promote2, setPromote2] = useState(BigNumber.from(0));
//   const [promote3, setPromote3] = useState(BigNumber.from(0));

//   useEffect(() => {
//     const work = async () => {
//       if (!account || !contracts) {
//         return;
//       }

//       const rc = contracts.reward.contract;

//       // 我在各个矿池的推荐收益
//       try {
//         const { inviteReward } = await rc.userData(account);
//         setPromote0(inviteReward);
//       } catch (err) {
//         console.error('inviteReward: ', 0, err.message);
//       }
//       try {
//         const { inviteReward } = await rc.userData(account);
//         setPromote1(inviteReward);
//       } catch (err) {
//         console.error('inviteReward: ', 1, err.message);
//       }
//       try {
//         const { inviteReward } = await rc.userData(account);
//         setPromote2(inviteReward);
//       } catch (err) {
//         console.error('inviteReward: ', 2, err.message);
//       }
//       try {
//         const { inviteReward } = await rc.userData(account);
//         setPromote3(inviteReward);
//       } catch (err) {
//         console.error('inviteReward: ', 3, err.message);
//       }

//       // 我的质押
//       try {
//         const { depositAmount } = await rc.userDeposit(0, account);
//         setStaked0(depositAmount);
//       } catch (err) {
//         console.error('get deposit amount: ', 0, err.message);
//       }
//       try {
//         const { depositAmount } = await rc.userDeposit(1, account);
//         setStaked1(depositAmount);
//       } catch (err) {
//         console.error('get deposit amount: ', 1, err.message);
//       }
//       try {
//         const { depositAmount } = await rc.userDeposit(2, account);
//         setStaked2(depositAmount);
//       } catch (err) {
//         console.error('get deposit amount: ', 2, err.message);
//       }
//       try {
//         const { depositAmount } = await rc.userDeposit(3, account);
//         setStaked3(depositAmount);
//       } catch (err) {
//         console.error('get deposit amount: ', 3, err.message);
//       }

//       // 各个矿池的总质押
//       try {
//         const amount = await contracts['LTT/CURRY'].uniTokenContract.totalSupply();
//         setStakeda0(amount);
//       } catch (err) {
//         console.error('get total deposit amount: ', 0, err.message);
//       }
//       try {
//         const amount = await contracts['CURRY/SBC'].uniTokenContract.totalSupply();
//         setStakeda1(amount);
//       } catch (err) {
//         console.error('get total deposit amount: ', 1, err.message);
//       }
//       try {
//         const amount = await contracts['ETH/WAS'].uniTokenContract.totalSupply();
//         setStakeda2(amount);
//       } catch (err) {
//         console.error('get total deposit amount: ', 2, err.message);
//       }
//       try {
//         const amount = await contracts['ETH/LTT'].uniTokenContract.totalSupply();
//         setStakeda3(amount);
//       } catch (err) {
//         console.error('get total deposit amount: ', 3, err.message);
//       }

//       // 我在各个矿池的挖矿收益
//       try {
//         const [amount] = await rc.viewReward(0, account);
//         setMined0(amount);
//       } catch (err) {
//         console.error('mined: ', 0, err.message);
//       }
//       try {
//         const [amount] = await rc.viewReward(1, account);
//         setMined1(amount);
//       } catch (err) {
//         console.error('mined: ', 1, err.message);
//       }
//       try {
//         const [amount] = await rc.viewReward(2, account);
//         setMined2(amount);
//       } catch (err) {
//         console.error('mined: ', 2, err.message);
//       }
//       try {
//         const [amount] = await rc.viewReward(3, account);
//         setMined3(amount);
//       } catch (err) {
//         console.error('mined: ', 3, err.message);
//       }
//     };

//     work();

//     const t = setInterval(work, 20000);

//     return () => clearInterval(t);
//   }, [account, contracts]);

//   return [
//     [staked0, staked1, staked2, staked3],
//     [stakeda0, stakeda1, stakeda2, stakeda3],
//     [mined0, mined1, mined2, mined3],
//     [promote0, promote1, promote2, promote3],
//   ];
// }

function Page({ lang, account, contracts }: Props) {
  const styles = useStyles();



  return (
    <Container>
  <Box className={styles.t1}>{l10n[lang].look}</Box>
    </Container>
  );
}

export default Page;