import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { BigNumber, utils } from 'ethers';
import Checkbox from "@material-ui/core/Checkbox";

import {
  Avatar,
  Box,
  Button,
  Grid,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  InputAdornment,
  IconButton,
  Snackbar,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import WCMD from '../assets/WCMD.png';
import { makeStyles } from '@material-ui/core/styles';

import { Props, roundEther, gt_0 } from '../helpers';
import {CheckBox} from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";



const l10n: { [key: string]: { [key: string]: any } } = {
  'EN': {
    deposit: 'deposit',
    amount: 'amount',
    earn: 'earn',
    choose: 'choose',
    earned: 'To be harvested',
    harvest: 'harvest',
    staked: 'Deposited',
    approve: 'Approve',
    withdraw: 'Redeem',
    available: 'Available balance',
    availableWithdraw: 'Available redeem',
    exit: 'Harvest and extract',
    referrer: 'Referrer address (nust, only once)',
    addressInvalid: 'address invalid',
    txSent: 'Transaction sent.',
    totalText: 'Current pool available total',
    buyText: 'Buy',
    harvestBut: 'One click harvest', poolText: "Ore pool",
    provide: "Provide liquidity",
    joinaward : "Join Ranking Award! (When Ranking started, address will be lock 7 days)",
    getRankAward : "Get Rank Award",
    rankAward : "Rank Award"

  },
  '中文': {
    deposit: '存入',
    amount: '金额',
    earn: '赚取',
    choose: '选择',
    earned: '待收获的',
    harvest: '收获',
    harvestBut: '一键收获',
    staked: '存入的',
    approve: '授权',
    withdraw: '赎回',
    available: '可用余额',
    availableWithdraw: '可赎回',
    exit: '收获并提取',
    referrer: '推荐人地址（必填，只有一次填写机会）',
    addressInvalid: '地址无效',
    txSent: '交易已发送。',
    totalText: '当前矿池可质押总',
    buyText: '购买', poolText: "矿池",
    provide: "提供流动性",
    joinaward: "参加排行奖励!(排行奖励开始时，地址将会被锁定7天无法提出)",
    getRankAward : "提取排行奖励",
    rankAward : "排行奖励"
  }
};

const useStyles = makeStyles({
  title: {
    fontSize: '120%',
    fontWeight: 'bold',
    marginTop: 30,
    marginBottom: 30,
    color: '#4c3444'
  },
  card: {
    background: "#17141A",
    borderRadius: 20,
    color: '#768b76',
    marginBottom: 2,
    width: "100%",
    paddingTop: 40,
    paddingBottom: 40,
  },
  card1: {
    background: "#17141A",
    borderRadius: 20,
    color: '#768b76',
    marginBottom: 2,
    width: "100%",
    paddingTop: 40,
    paddingBottom: 40,
  },
  iconCtn: {
    fontSize: 36,
    height: 100,
    width: 100,
    borderRadius: 50,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto 16px',
  },
  avatar: {
    backgroundColor: 'transparent',
    height: 80,
    width: 80,
    fontSize: '110%'
  },
  t1: {
    color: '#737276',
    fontSize: '140%',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  t2: {
    fontSize: '90%',
    color: '#737276',

  },
  t3: {
    fontSize: '130%',
    color: '#ffffff',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  t4: {
    fontSize: '130%',
    color: '#09E2D5',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  btn: {
    backgroundColor: '#143D3F',
    display: 'block',
    textDecoration: 'none',
    color: '#09E2D5',
    fontSize: '110%',

    lineHeight: 2,
    borderRadius: 14,

  },
  btn2: {
    backgroundColor: '#4C1F3D',
    display: 'block',
    textDecoration: 'none',
    color: '#F222A1',
    fontSize: '110%',

    lineHeight: 2,
    borderRadius: 14,

  },
  inline: {
    width: "95%",
    display: 'flex',
    justifyContent: "space-between"
  },
  inlineBtn: {
    margin: '0 6px',
    backgroundColor: '#143D3F',
    textDecoration: 'none',
    color: '#09E2D5',
    width: '50%',
    fontSize: '100%',

    lineHeight: 2.3,
    borderRadius: 30,
  },

  inlineBtn2: {
    margin: '0 6px',
    backgroundColor: '#452B14',
    textDecoration: 'none',
    color: '#FF8900',
    width: '50%',
    fontSize: '100%',

    lineHeight: 2.3,
    borderRadius: 30,
  },

  iconCtn2: {
    fontSize: 36,
    height: 90,
    width: 150,
    borderRadius: 50,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto 16px',
  },
  avatar2: {
    backgroundColor: 'transparent',
    height: 90,
    width: 150,
    fontSize: '110%'
  },
});

function Page({ lang, account, contracts, myProviders }: Props) {
  const params: any = useParams();
  const coin = params.SingleMining;
  const [miningLogo, setLogo] = useState('');
  const [miningName, setName] = useState('');
  const [miningSave, setSave] = useState('');
  const [buyUrl, setBuyUrl] = useState('');
  const [supportUrl, setSupportUrl] = useState('');
  const [buyName, setBuyName] = useState('');

  const styles = useStyles();
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [depositAmount, setDepositAmount] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [referrer, setReferrer] = useState('');
  const [referrerError, setReferrerError] = useState(null);
  const [snackbar, setSnackbar] = useState(false);

  const [isNew, setIsNew] = useState(true);
  const [uniBalance, setUniBalance] = useState(BigNumber.from(0));
  const [reward, setReward] = useState(BigNumber.from(0));
  const [staked, setStaked] = useState(BigNumber.from(0));
  const [allowance, setAllowance] = useState(BigNumber.from(0));
  const [totalStakedLp, setAlltotalStakedLp] = useState(BigNumber.from(0));
  const [award, setAward] = useState(BigNumber.from(0));

  const [join,setJoin] = useState(false);

  useEffect(() => {
    const work = () => {
      if (!account || !contracts ||!myProviders ) {
        return;
      }
      setLogo(contracts[coin].icon)
      setName(contracts[coin].name)
      setSave(contracts[coin].save)
      setBuyUrl(contracts[coin].buyUrl)
      if (coin == "5" || coin == "6" || coin == "7" || coin == "8") {
        setName(contracts[coin].name2)
        setBuyName(contracts[coin].buyName)
        setSupportUrl(contracts[coin].supportUrl)
        console.log(contracts[coin].supportUrl)
      } else {
        setName(contracts[coin].name)
      }
      //我的 UNI 余额

      // if (coin == "2") {
      //
      //   myProviders.getBalance(account).then((amount:BigNumber)=>{
      //     setUniBalance(amount);
      //   }).catch((err: any) => {
      //     console.error('get uni provider: ', coin, err.message)
      //   });
      //   //
      // } else {

      console.log("contract :" + coin)
      // console.log(contracts[coin])
        contracts[coin].uniTokenContract.balanceOf(account)
          .then((amount: BigNumber) => {
            console.log("amount " + amount)
            setUniBalance(amount);
          })
          .catch((err: any) => {
            console.error('get uni balanceOf: ', coin, err.message)
          });
      // }




      // 我的许可
      contracts[coin].uniTokenContract.allowance(account, contracts.pool.address)
        .then((amount: BigNumber) => {
          // if (coin == "2") {
          //   setAllowance(BigNumber.from('999999999999999999'));
          // } else {
            setAllowance(amount);
          // }

        })
        .catch((err: any) => {
          console.error('get allowance: ', coin, err.message)
        });
      // 总质押
      contracts.pool.contract.totalStaked(coin)
        .then((amount: BigNumber) => {
          console.error('totalStaked: ', coin, amount)
          setAlltotalStakedLp(amount);
        })
        .catch((err: any) => {
          console.error('totalStaked: ', coin, err.message)
        });
      // 一质押
      contracts.pool.contract.userStaked(account, coin)
        .then((amount: BigNumber) => {
          setStaked(amount)
        })
        .catch((err: any) => {
          console.error('userStaked: ', coin, err.message)
        });

      contracts.pool.contract.calcwcmdStaticReward(account, coin)
        .then((amount: BigNumber) => {
          setReward(amount)
        })
        .catch((err: any) => {
          console.error('calcwcmdStaticReward: ', coin, err.message)
        });


      contracts.pool.contract.userInfo(account)
        .then((res: any) => {
          if (res.invitor == "0x0000000000000000000000000000000000000000") {
            setIsNew(true)
          } else {
            setIsNew(false)
            setReferrer(res.invitor)
          }
        })
        .catch((err: any) => {
          console.error('userInfo: ', coin, err.message)
        });

      contracts.pool.contract.checkTotalRewardForRound(coin)
          .then((res: any) => {
            setAward(res)
          })
          .catch((err: any) => {
            console.error('userInfoz: ', coin, err.message)
          });


    };

    work();

    const t = setInterval(work, 20000);

    return () => clearInterval(t);
  }, [account, contracts, coin]);

  const onApprove = () => {
    contracts[coin].uniTokenContract.approve(
      contracts.pool.address,
      BigNumber.from('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'))
      .then((amount: BigNumber) => {
        // console.error('approve: ', coin, amount)
        // setAllowance(BigNumber.from('999999999'));
        setSnackbar(true);
      })
      .catch((err: any) => {
        console.error('approve: ', coin, err.message)
      });
  };

  /**
   * getrewards
   */
  const onHarvest = () => {
    contracts.pool.contract.getRewards(contracts[coin].id)
      .then(() => {
        setSnackbar(true);
      })
      .catch((err: any) => {
        console.error('harvest: ', coin, err.message)
      });
  };

  /**
   * mintlp
   **/
  const onDeposit = () => {

    if (referrer !== '' && (!utils.isAddress(referrer) || referrer.toLowerCase() === account?.toLowerCase())) {
      setReferrerError(l10n[lang].addressInvalid);
      return;
    }

    setReferrerError(null);
    setOpenDeposit(false);

    const amount = utils.parseEther(depositAmount);
    contracts.pool.contract.mineLp(amount, contracts[coin].id, referrer, join)
        .then(() => {
          setSnackbar(true);
        })
        .catch((err: any) => {
          console.error('deposit: ', coin, err.message)
        });

  };

  /**
   * exitLp
   **/
  const onWithdraw = () => {
    console.log("withdraw")
    // setOpenWithdraw(false);
    //
    // const amount = utils.parseEther(withdrawAmount);
    // contracts.pool.contract.exitLp(contracts[coin].id)
    //   .then(() => {
    //     setSnackbar(true);
    //   })
    //   .catch((err: any) => {
    //     console.error('withdraw: ', coin, err.message)
    //   });
  };

  const onExit = () => {
    // 提取全部
      contracts.pool.contract.exitLp(contracts[coin].id)
        .then(() => {
          setSnackbar(true);
        })
        .catch((err: any) => {
          console.error('exit: ', coin, err.message)
        });
  };
  const getaward = () => {
    // 提取全部
    contracts.pool.contract.withdrawRewards(contracts[coin].id)
        .then(() => {
          setSnackbar(true);
        })
        .catch((err: any) => {
          console.error('get rank award: ', coin, err.message)
        });
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setJoin(event.target.checked);
  }

  return (
    <Box style={{ textAlign: 'center' }}>

      <Container>
        <Grid container spacing={2} justify="center">


          <Grid item xs={12}>
            <Box className={styles.card}>
              {
                coin == "5" || coin == "6" || coin == "7" || coin == "8"?
                  <Box>
                    <Box className={styles.iconCtn2}>
                      <Avatar className={styles.avatar2} src={miningLogo} />
                    </Box>
                    <Box className={styles.t3}>{miningName} {l10n[lang].poolText}</Box>
                  </Box>
                  :
                  <Box>
                    <Box className={styles.iconCtn}>
                      <Avatar className={styles.avatar} src={miningLogo} />
                    </Box>
                    <Box className={styles.t3}>{miningName}</Box>
                  </Box>

              }



              <span className={styles.t2}>{l10n[lang].deposit}{miningSave} {l10n[lang].harvest}HWCMDPRO</span>
              <Box className={styles.t2} style={{ marginTop: 40 }}>{l10n[lang].totalText} {coin == "5" || coin == "6" || coin == "7" || coin == "8" ? "LP" : miningSave}</Box>
              <Box className={styles.t4}> { coin == "1" || coin == "2" || coin == "3" || coin == "4" ? roundEther(uniBalance,6): roundEther(uniBalance, 6)}</Box>

              {
                coin == "5" || coin == "6" || coin == "7" || coin == "8"?
                  <Box style={{ marginTop: 30, marginRight: "30px", marginLeft: '30px' }} className={styles.inline}>
                     <a href={buyUrl} target="_blank" className={styles.inlineBtn} rel="noreferrer">{l10n[lang].buyText}{buyName}</a>
                    <a href={supportUrl} target="_blank" className={styles.inlineBtn2} rel="noreferrer">{l10n[lang].provide}</a>
                  </Box>
                  :
                  <Box style={{ marginTop: 30, marginRight: "30px", marginLeft: '30px' }}>
                    <a href={buyUrl} target="_blank" className={styles.btn} rel="noreferrer">{l10n[lang].buyText}</a>
                  </Box>
              }
            </Box>
          </Grid>


          <Grid item xs={12}>
            <Box className={styles.card}>
              <Box className={styles.iconCtn}>
                <Avatar className={styles.avatar} src={WCMD} />
              </Box>
              <Box className={styles.t2}>{l10n[lang].earned} HWCMDPRO</Box>
              <Box className={styles.t4} style={{ marginBottom: 30 }}>{roundEther(reward, 6)}</Box>
              <Box style={{ margin: '0 30px' }}>
                <Button
                  fullWidth
                  className={styles.btn}
                  disabled={reward.eq(0)}
                  onClick={onHarvest}
                >{l10n[lang].harvestBut}</Button>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box className={styles.card}>
              {
                coin == "5" || coin == "6" || coin == "7" || coin == "8" ? <Box className={styles.iconCtn2}>
                  <Avatar className={styles.avatar2} src={miningLogo} />
                </Box> : <Box className={styles.iconCtn}>
                  <Avatar className={styles.avatar} src={miningLogo} />
                </Box>
              }
              <Box className={styles.t2}>{l10n[lang].staked} {coin == "5" || coin == "6" || coin == "7" || coin == "8" ? "LP" : miningSave}</Box>
              <Box className={styles.t4}>{roundEther(staked, 6)}</Box>
              <Box style={{ margin: '0 30px' }} >
                {
                  allowance.gt(0)
                    ?
                    <>
                      <Button className={styles.btn} fullWidth onClick={() => setOpenDeposit(true)}>{l10n[lang].deposit}</Button>
                    </>
                    :
                    <Button
                      fullWidth
                      className={styles.btn}
                      disabled={account === null}
                      onClick={onApprove}
                    >{l10n[lang].approve}</Button>
                }
              </Box>
            </Box>
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <Box className={styles.card}>*/}
          {/*    <Box style={{ marginTop: 30, marginBottom: 80 }}>*/}
          {/*      <Box className={styles.t2}>{l10n[lang].rankAward}</Box>*/}
          {/*      <Box className={styles.t4}>{roundEther(award, 6)}</Box>*/}
          {/*      <Button*/}
          {/*          fullWidth*/}
          {/*          className={styles.btn2}*/}
          {/*          disabled={award.isZero()}*/}
          {/*          onClick={getaward}*/}
          {/*      >{l10n[lang].getRankAward}</Button>*/}
          {/*    </Box>*/}
          {/*  </Box>*/}
          {/*</Grid>*/}

        </Grid>

        <Box style={{ marginTop: 30, marginBottom: 80 }}>
          <Button
            fullWidth
            className={styles.btn2}
            disabled={staked.isZero()}
            onClick={onExit}
          >{l10n[lang].exit}</Button>
        </Box>



      </Container>

      {/* 抵押对话框 */}
      <Dialog
        open={openDeposit}
        onClose={() => setOpenDeposit(false)}
      >
        <DialogTitle>
          {l10n[lang].deposit}
          <IconButton
            style={{ position: 'absolute', top: 0, right: 0 }}
            onClick={() => setOpenDeposit(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {l10n[lang].available}<br />
            {roundEther(uniBalance, 6)}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label={l10n[lang].amount}
            variant="outlined"
            type="text"
            fullWidth
            value={depositAmount}
            onChange={(event) => { setDepositAmount(event.target.value) }}
            InputProps={{
              endAdornment:
                <InputAdornment position="start">
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    style={{ minWidth: '32px', padding: '0 2px' }}
                    onClick={() => setDepositAmount(roundEther(uniBalance, 6))}
                  >MAX</Button>
                </InputAdornment>,
            }}
          />

          {/* 推荐人输入框 */}
          {
            (!isNew) ? null :
              <TextField
                margin="dense"
                error={!!referrerError}
                helperText={referrerError}
                label={l10n[lang].referrer}
                variant="outlined"
                type="text"
                fullWidth
                value={referrer}
                onChange={(event) => { setReferrer(event.target.value.trim()) }}
              />
          }
          {
            <FormControlLabel
                control={<Checkbox checked={join} onChange={handleChange} name="checked"/>}
                label ={l10n[lang].joinaward}
            />
          }
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            onClick={onDeposit}
            variant="contained"
            color="secondary"
            disabled={!gt_0(depositAmount)}
          >{l10n[lang].deposit}</Button>
        </DialogActions>
      </Dialog>

      {/* 赎回对话框 */}
      <Dialog
        open={openWithdraw}
        onClose={() => setOpenWithdraw(false)}
      >
        <DialogTitle>
          {l10n[lang].withdraw}
          <IconButton
            style={{ position: 'absolute', top: 0, right: 0 }}
            onClick={() => setOpenWithdraw(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {l10n[lang].availableWithdraw} MR:<br />
            {roundEther(staked, 6)}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label={l10n[lang].amount}
            variant="outlined"
            type="text"
            fullWidth
            value={withdrawAmount}
            onChange={(event) => { setWithdrawAmount(event.target.value) }}
            InputProps={{
              endAdornment:
                <InputAdornment position="start">
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    style={{ minWidth: '32px', padding: '0 2px' }}
                    onClick={() => setWithdrawAmount(roundEther(staked, 6))}
                  >MAX</Button>
                </InputAdornment>,
            }}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            onClick={onWithdraw}
            variant="contained"
            color="secondary"
            disabled={!gt_0(withdrawAmount)}
          >{l10n[lang].withdraw}</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackbar}
        autoHideDuration={2000}
        message={l10n[lang].txSent}
        onClose={(event: any, reason?: string) => {
          if (reason === 'clickaway') {
            return;
          }
          setSnackbar(false);
        }}
      />

    </Box>
  );
}

export default Page;
