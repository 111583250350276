import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Container,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { Props } from '../helpers';
import WCMD from "../assets/WCMD.png";
import first  from "../assets/sort/0.png";
import second from "../assets/sort/1.png";
import three  from "../assets/sort/2.png";
import goLeft  from "../assets/left.png";
import blower  from "./css/blower";
import mobile  from "./css/mobile";
import {Link} from "react-router-dom";
const os = function() {
  let ua = navigator.userAgent,
      isWindowsPhone = /(?:Windows Phone)/.test(ua),
      isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
      isAndroid = /(?:Android)/.test(ua),
      isFireFox = /(?:Firefox)/.test(ua),
      isChrome = /(?:Chrome|CriOS)/.test(ua),
      isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
      isPhone = /(?:iPhone)/.test(ua) && !isTablet,
      isPc = !isPhone && !isAndroid && !isSymbian;
  return {
    isTablet: isTablet,
    isPhone: isPhone,
    isAndroid : isAndroid,
    isPc : isPc,
    isMobile: isPhone || isAndroid
  };
}();

const rankedSortIcon = [first, second, three];
let   loadCurrent = true;

const l10n: { [key: string]: { [key: string]: any } } = {
  'EN': {
    // sort: ['First', '第二轮']
    sort: ['Round 1', 'Round 2', 'Round 3', 'Round 4', 'Round 5', 'Round 6', ' Round7'],
    activity: "Index",
    category: "Pool Type",
    status: "Status",
    symbol: "Symbol",
    pledgeTotal: "Total Pledge",
    partIn: "No. ",
    poolReward: "Award",
    signal: "Pledge Currency",
    buttonReward: 'Detail',
    buttonPledge: 'Detail',
    mobility: 'Pledge Liquidity',
    rewardTitle: 'Reward',
    rewardUnit: 'HWCMD',
    rankedNo: 'Rank',
    rankedAddress: 'Address',
    rankedAmountReward: 'Award',
    rankedAmountPledge: 'Pledge',
    activityStatusShow: [ 'Reward', 'Processing', 'No Start'],
    poolStatus: ['Unactivated', 'Mining', 'Settled', 'No Start'],
    goPartIn: 'Pledge',
    endDate: 'End Time'
  },
  '中文': {
    sort: ['第一轮', '第二轮', '第三轮', '第四轮', '第五轮', '第六轮', '第七轮'],
    activity: "活动序号",
    category: "矿池类别",
    status: "状态",
    symbol: "币种编码",
    pledgeTotal: "质押总额",
    partIn: "参与人数",
    poolReward: "奖励总额",
    signal: "单币",
    buttonReward: '奖励',
    buttonPledge: '排行榜',
    mobility: '流动性',
    rewardTitle: '奖励',
    rewardUnit: 'WCMD',
    rankedNo: '排名',
    rankedAddress: '参与地址',
    rankedAmountReward: '奖励金额',
    rankedAmountPledge: '抵押金额',
    activityStatusShow: [ '已奖励', '进行中', '未开始'],
    poolStatus: ['未激活', '挖矿中', '已结算', '未开始'],
    goPartIn: '去抵押',
    endDate: '截止时间'
  }
};

// @ts-ignore
const useStyles = makeStyles(os.isMobile ? mobile : blower);

function clear() {
  return <Box style={{clear: 'both'}}/>;
}
const _timeFormatRex = [/Y+/,/M+/,/D+/,/H+/,/m+/,/s+/,/S+/];

function ellipsis (str = '', size = 24){
  if (!str || str.length <= size || size < 5){
    return str;
  }
  size -= 3;
  let q = size/2 - 1
  return `${str.substring(0, size - q)}...${str.substring(str.length - q)}`
}

/**
 * 格式化时间
 * @param format
 * @param date
 */
function  dateFormat (date: string | number | Date | undefined){
  if(!date || Object.prototype.toString.call(date) !== '[object Date]') {
    date = date ? new Date(date) : new Date();
  }
  let format = "YYYY-MM-DD HH:mm:ss";
  // @ts-ignore
  let v, value = [date.getFullYear(), date.getMonth()+1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()];
  // @ts-ignore
  _timeFormatRex.forEach((rex, i) => {
    if(!rex.test(format)){
      return
    }
    v = value[i]
    // @ts-ignore
    format = format.replace(rex, i == 0 ? v : v < 10 ? '0' + v : v );
  });
  return format;
}


let rankedListInterval: any = undefined;

/* 本地数据缓存 */
let cache = {};


function Page(this: any, { lang, account, contracts = {} }: Props) {

  const language = l10n[lang]
  const styles = useStyles();


  let [data, setData] = useState({
    /* 是否加载成功 */
    success: 0,
    /* 当前显示下标 */
    i:  0,
    /* 正在进行的下标 */
    current: 0,
    /* 是否显示活动列表 */
    show: true,
    /* 开始时间 */
    start: 1,
    /* 截止时间 */
    endDate: '',

    /* 测试数据 */
    num: 1,
    str: 'str',
    bool: true,
  });
  /* 地址抵押奖励查询查询 */
  let [ranked, setRanked] = useState({
    /* 点击进入的选择先 */
    pressPoolIndex: 0,
    /* 排行榜数据 */
    list: [],
    choose: {
      symbol: '',
      partIn: 0,
      status: '',
    }

  })
  let [fixedActivityList, setFixedActivityList] = useState(buildEmptyData());
  let [mobilityActivityList, setMobilityActivityList] = useState(buildEmptyData());
  let [activityListFromChain, setActivityListFromChain] = useState([]);
  let [poolRewardAmount, setPoolRewardAmount] = useState([]);

  /**
   * 加载本轮矿池奖励总额
   */
  function loadPoolRewardAmount() {
    if (data.i >= data.current) {
      return;
    }
    /* 构建缓存Key */
    let key = 'loadPoolRewardAmount_' + data.i;
    // @ts-ignore
    if (cache[key]){
      // @ts-ignore
      flush(setPoolRewardAmount, cache[key])
    }

    /* 从链上加载 */
    let contract = getPool();
    contract.getRewardPoorForRound(data.i + 1).then((list: any = []) => {
      /*console.log("getRewardPoorForRound", data.i + 1, list, JSON.stringify(list))*/
      let l: any[] = []
      list.forEach((e: any) => {
        if (!e || e.length < 2){
          return;
        }
        l.push(e.reward._hex - 0)
      })
      // @ts-ignore
      cache[key] = l
      flush(setPoolRewardAmount, l)


    }).catch(console.log)

  }

  /**
   * 必须创建初始对象， 否则无法实现双向数据绑定
   */
  function buildEmptyData() {
    let i = 0, list =[];
    while (i++ < 4){
      list.push({
        symbol: undefined,
        icon: undefined,
        id: undefined,
        partIn: undefined,
        status: undefined
      });
    }
    return list;
  }

  /**
   * 获取当前轮前100 信息.  (地址，质押数量）
   * getTop100（矿池id, 100, 本轮活动开始时间）
   * @param data
   * @param contract
   */
  function rankedFlush() {
    return new Promise<void>((resolve, reject) => {
      if (!activityListFromChain || data.i == -1) {
        // reject("no activity data");
        return;
      }

      let contract = getPool()
      contract.getTop100(ranked.pressPoolIndex + 1, 1, data.start).then((list = []) => {
        // console.log(list);
        let l: { address: any; amount: number; }[] = [];
        list.forEach((e: any) => {
          // @ts-ignore
          if (!e || e.length < 4){
            return;
          }
          l.push({address: e[0], amount: e[1]._hex/rate || 0});
        });
        flush(setRanked, ranked, 'list', l);
        resolve();
      }).catch(console.log);
    });
  }

  /**
   * 获取奖励
   * @param contract
   */
  function rewardHistory() {
    return new Promise<void>((resolve, reject) => {
      if (!activityListFromChain || data.i == -1) {
        reject("no activity data");
        return;
      }
      let contract = getPool();
      contract.getTop100RewardInfo(ranked.pressPoolIndex + 1, data.i + 1).then((list = []) => {
        let l: { address: any; amount: number; reward: any;}[] = [];
        list.forEach((e: any) => {
          // @ts-ignore
          if (!e || e.length < 4){
            return;
          }
          l.push({
            address: e.user,
            amount: e.staked._hex/rate || 0,
            reward: e.reward._hex/rate || '-'
          });
        });

        flush(setRanked, ranked, 'list', l);
        resolve();
      }).catch(reject);
    });
  }

  /**
   * 获取活动列表信息
   */
  const rate = Math.pow(10, 18);
  function loadActivityListAndGetStartTime(contract: { getRewardTypeInformation: () => Promise<any>; roundStart: () => Promise<any>; }) {
    return new Promise<void>((resolve, reject) => {
      if (activityListFromChain && activityListFromChain.length > 0){
        resolve();
        return;
      }else if (!contract || !contract.getRewardTypeInformation || !(contract.getRewardTypeInformation instanceof Function) || !contract.roundStart){
        reject('load error');
      }

      /* 获取当前开始时间 */
      contract.roundStart().then((ts: any) => {
        data.start = ts && ts._hex ? ts._hex - 0 : 0;
        if (data.start > 0){
          let ss = data.start + 7 * 24 * 60 * 60;
          data.endDate = dateFormat(new Date(ss * 1000))
        }

        flush(setData, data)
      }).catch(reject);

      /* 获取7轮分配信息*/
      contract.getRewardTypeInformation().then(async (out: any) => {
        if (!out) {
          reject('load error');
          return;
        }
        let l: any[] = [], pending = false;
        for (let i = 0;  i < out.length; i ++) {
          let one = { 'fixed': Math.round(out[i][0]._hex / rate), 'mobility': Math.round(out[i][1]._hex / rate) };
          // @ts-ignore
          l.push(one);
          if (pending){
            continue;
          }
          /* 查询开始时间 */
        }
        // @ts-ignore
        setActivityListFromChain(l);
        resolve();
      }).catch(reject);
    });
  }

  function getPool() {
    if (!contracts || !contracts.pool) {
      throw 'no contracts'
    }
    return contracts.pool.contract;
  }

  /**
   * 刷新人数
   * @param contract
   */
  function flushPartIn() {
    return new Promise<void>((resolve, reject) => {
      let contract = getPool();
      if (!contract || !contract.getCount || !data.start){
        reject('load error');
      }
      fixedActivityList.forEach((v: any, i) => {
        contract.getCount(i+1, data.start).then(async (r: any) => {
          v.partIn = r && r._hex ? r._hex - 0 : 0;
          if (data.i == data.current) {
            v.status = v.partIn > 10 ? language.poolStatus[0] : language.poolStatus[1]
          }else if (data.i < data.current){
            v.status = language.poolStatus[2]
          } else {
            v.status = language.poolStatus[3]
          }
        }).catch(reject);
      });
      mobilityActivityList.forEach((v: any, i) => {
        contract.getCount(i+1+4, data.start).then(async (r: any) => {
          v.partIn = r && r._hex ? r._hex - 0 : 0;
          if (data.i == data.current) {
            v.status = v.partIn > 10 ? language.poolStatus[0] : language.poolStatus[1]
          }else if (data.i < data.current){
            v.status = language.poolStatus[2]
          } else {
            v.status = language.poolStatus[3]
          }
        }).catch(reject);
      });
    });
  }

  /**
   * 获取币种信息
   */
  async function fetchPollData() {
    if (!contracts) {
      return
    }
    let i = 0;
    while (++i <= 8){
      if (!contracts[i]){
        continue;
      }
      let one = i <= 4 ? fixedActivityList[(i - 1) % 4] : mobilityActivityList[(i - 1) % 4];
      if (!one){
        continue;
      }
      one.symbol  = contracts[i].name;
      one.icon    = contracts[i].icon;
      one.id      = contracts[i].id;
    }
  }

  /* 初始化基础数据 */
  function init() {
    return new Promise(async (resolve, reject) => {
      if (contracts && contracts.pool) {
        data.success = 1;
      }
      /* 抓取矿池信息 */
      fetchPollData().catch(reject);
      if (!contracts || !contracts.pool){
        return
      }
      let contract = contracts.pool.contract;

      /* 加载正在运行的活动 */
      let index = await contract.index()
      if (index && index._hex){
        data.current = index._hex - 1 || 0;
        if (loadCurrent){
          data.i = data.current<=-1?0:data.current;
          loadCurrent = false;
        }
      }

      /* 加载活动 */
      await loadActivityListAndGetStartTime(contract);

      /* 刷新加入用户人数 */
      flushPartIn().catch(console.log);
    });

  }

  init().catch(console.log)

  const toggle = (i: any) => {
    if (!isNaN(i)){
      flush(setRanked, ranked, 'pressPoolIndex', i)
    }
    if (data.show) {
      let list = (ranked.pressPoolIndex > 3 ? mobilityActivityList : fixedActivityList)[ranked.pressPoolIndex % 4];
      // @ts-ignore
      flush(setRanked, ranked, 'choose', JSON.parse(JSON.stringify(list)), 'list', [])

      if (data.current == data.i && !rankedListInterval){
        rankedListInterval = setInterval(rankedFlush, 3000)
        rankedFlush().catch(console.log)
      }else if (data.current > data.i) {
        rewardHistory().catch(console.log)
      }
    }else if (rankedListInterval) {
      clearInterval(rankedListInterval);
    }
    flush(setData, data, 'show', !data.show)
  }

  /**
   * 构建矿池网页数据
   **/
  function buildPoolItemHtml(list: any[], iv: number){
    return list.map((item, idx) =>
        item.symbol
            ?
            <Box className={styles.itemItem} key={'fixed_' + idx + iv}>
              <Box className={styles.itemItemIcon} style={{float: "left", width: '20%'}}>
                <Avatar className={styles.icon} src={item.icon} />
              </Box>
              <Box style={{float: "left", width: '55%', marginLeft: '5%'}}>
                <ul className={styles.leftTitle}>
                  <li style={{fontWeight: "bold"}}>{language.status}</li>
                  <li style={{fontWeight: "bold"}}>{language.symbol}</li>
                  <li style={{fontWeight: "bold"}}>{language.partIn}</li>
                  {
                    poolRewardAmount && poolRewardAmount.length > 0
                        ? <li style={{fontSize:'14px', fontWeight: "bold"}}>{language.poolReward}</li>
                        : null
                  }
                </ul>
                <ul className={styles.rightContent}>
                  <li className={styles.rightContentLi} >{item.status}</li>
                  <li className={styles.rightContentLi} >{item.symbol}</li>
                  <li className={styles.rightContentLi} >{item.partIn}</li>
                  {
                    poolRewardAmount && poolRewardAmount.length > 0
                        ? <li className={styles.rightContentLi} style={{fontSize:'14px'}}>{poolRewardAmount[idx + iv]}</li>
                        : null
                  }
                </ul>
              </Box>
              <Box style={{float: "left", width: '12%'}}>
                {
                  data.i == data.current ?
                      <Link to={'/mining/single/' + (idx + iv + 1)}>
                        <Button className={styles.button} >{language.goPartIn}</Button>
                      </Link>
                      : null
                }
                {
                  data.i <= data.current ? <Button className={styles.button} onClick={ () => toggle(idx + iv) } style={{marginTop: data.i != data.current ? '' : '30%'}}>{data.i >= data.current && false ? language.buttonPledge: language.buttonReward}</Button> : null
                }
              </Box>
            </Box>
            : null
    )
  }
  // function buildPoolItemHtml(list: any[]){
  //   return list.map((item, idx) =>
  //       item.symbol
  //           ?
  //           <Box className={styles.itemItem} key={'fixed_' + idx}>
  //             <Box className={styles.itemItemIcon} style={{float: "left", width: '20%'}}>
  //               <Avatar className={styles.icon} src={item.icon} />
  //             </Box>
  //             <Box style={{float: "left", width: '55%', marginLeft: '5%'}}>
  //               <ul className={styles.leftTitle}>
  //                 <li style={{fontWeight: "bold"}}>{language.status}</li>
  //                 <li style={{fontWeight: "bold"}}>{language.symbol}</li>
  //                 <li style={{fontWeight: "bold"}}>{language.partIn}</li>
  //                 {
  //                   poolRewardAmount && poolRewardAmount.length > 0
  //                       ? <li style={{fontSize:'14px', fontWeight: "bold"}}>{language.poolReward}</li>
  //                       : null
  //                 }
  //               </ul>
  //               <ul className={styles.rightContent}>
  //                 <li className={styles.rightContentLi} >{item.status}</li>
  //                 <li className={styles.rightContentLi} >{item.symbol}</li>
  //                 <li className={styles.rightContentLi} >{item.partIn}</li>
  //                 {
  //                   poolRewardAmount && poolRewardAmount.length > 0
  //                       ? <li className={styles.rightContentLi} style={{fontSize:'14px'}}>{poolRewardAmount[idx]}</li>
  //                       : null
  //                 }
  //               </ul>
  //             </Box>
  //             <Box style={{float: "left", width: '12%'}}>
  //               {
  //                 data.i == data.current ?
  //                     <Link to={'/mining/single/' + (idx+1)}>
  //                       <Button className={styles.button} >{language.goPartIn}</Button>
  //                     </Link>
  //                     : null
  //               }
  //               {
  //                 data.i <= data.current ? <Button className={styles.button} onClick={ () => toggle(idx) } style={{marginTop: data.i != data.current ? '' : '30%'}}>{data.i >= data.current && false ? language.buttonPledge: language.buttonReward}</Button> : null
  //               }
  //             </Box>
  //           </Box>
  //           : null
  //   )
  // }


  useEffect( ()  => {
    let t = setInterval(async () => {
      if (data.i == data.current){
        flushPartIn().catch(console.log);
      }
      flush(setData, data, 'num', data.num +1, 'str', 'str' + +new Date, 'bool', !data.bool)
    }, 10000);
    return () => clearInterval(t);
  });


  /**
   * 双向数据绑定
   * @param set
   * @param data
   * @param ele
   */
  function flush(set: (arg0: any) => void, data: { [x: string]: any; }, ... ele: any[]) {
    if (!set || typeof set != 'function') {
      return
    }else if (typeof data  != 'object' || !ele || ele.length == 0){
      set(data)
    }else {
      for (let i = 0; i < ele.length; i += 2) {
        data[ele[i]] = ele[i+1];
      }
      set(JSON.parse(JSON.stringify(data)))
    }
  }

  function get(key: string | number){
    return activityListFromChain && activityListFromChain[data.i] ? activityListFromChain[data.i][key] : 0
  }

  /**
   * 翻页
   **/
  function turnPage(i: number) {
    let v = data.i + i;
    if (v < 0 || v > 6){
      return;
    }
    flush(setData, data, 'i', v)
    flush(setPoolRewardAmount, [])
    loadPoolRewardAmount()
  }

  return (
      <Container>
        <Box className={styles.item}>
          <p className={styles.status} style={{ backgroundColor: ['grey', 'green', '#c1bbbb6e'][data.i < data.current ? 0 : data.i == data.current ? 1 : 2] }}>
            <span className={styles.statusSpan}>{language.activityStatusShow[data.i < data.current ? 0 : data.i == data.current ? 1 : 2]  }</span>
          </p>

          {/* 活动进程 */}
          {
            data.show
                ? <Box style={{display: "inline-block", width: '100%', paddingBottom: '2%'}}>
                    <Box style={{ width: '100%', marginBottom: '20px'}}>
                      <Avatar style={{display: "inline-block",height: '16px'}} src={goLeft} onClick = {() => {turnPage(-1)}}/>
                      <h2 style={{display: "inline-block", margin: '0 5%'}}>{language.sort[data.i]}</h2>
                      <Avatar style={{display: "inline-block", transform: 'rotateY(180deg)',height: '16px'}} src={goLeft} onClick = {() => {turnPage(1)}} />

                    </Box>
                    {
                      data.current == data.i
                          ? <Box className={styles.endDateStyle}> {language.endDate}： {data.endDate}</Box>
                          : <Box style={{width: '100%', height: "30px"}}></Box>
                    }

                </Box>
                : null
          }

          {(
              data.show
                  /* 基础币种 */
                  ? <Box style={{padding: 0, margin: 0, width: '100%'}}>
                    {/* 单币挖矿 */}
                    <Box>
                      {/* 矿池类型 */}
                      <Box className={styles.poolType}>
                        <p>{language.signal}  （<span>{`${language.rewardTitle} ${get('fixed')} ${language.rewardUnit}`}</span>）  </p>
                      </Box>
                      {/* 矿池列表 */}
                      {buildPoolItemHtml(fixedActivityList,0)}
                      {clear()}
                    </Box>

                    {/* 流动性挖矿 */}
                    <Box style={{marginTop: '20px'}}>
                      {/* 矿池类型 */}
                      <Box className={styles.poolType}>
                        <p>{language.mobility}  （<span>{`${language.rewardTitle} ${get('mobility') } ${language.rewardUnit}`}</span>）  </p>
                      </Box>

                      {/* 矿池列表 */}
                      {buildPoolItemHtml(mobilityActivityList,4)}
                    </Box>
                    {clear()}
                  </Box>
                  /* 地址列表 */
                  : <Box>
                    <Box style={{textAlign: 'left', width: '80%',padding: '30px 50px'}}>
                      <Button className={styles.returnButton} onClick={ toggle }>返回</Button>
                    </Box>
                    <Box style={{display: "inline-block", width: '95%', textAlign:"left", padding: '0 0 50px 0'}}>
                      <ul className={styles.rankedTitle} style={{width: os.isMobile ? '30%' : '10%', textAlign: 'right', fontWeight: "bold", letterSpacing:'1px'}}>
                        <li>{language.activity}</li>
                        <li>{language.category}</li>
                        <li>{language.status}</li>
                        <li>{language.symbol}</li>
                        {/*<li>{language.partIn}</li>*/}
                      </ul>
                      <ul className={styles.rankedTitle}  style={{width: '60%', marginLeft: '20px'}}>
                        <li>{ language.sort[data.i] }</li>
                        <li>{ ranked.pressPoolIndex > 3 ? language.mobility: language.signal }</li>
                        <li>{ ranked.choose.status }</li>
                        <li>{ ranked.choose.symbol }</li>
                        {/*<li>{ ranked.choose.partIn }</li>*/}
                      </ul>
                    </Box>
                    {/* 地址列表 */}
                    {
                      ranked.list && ranked.list.length == 0
                          ? null
                          :
                          <Box style={{textAlign: 'center'}}>
                            <ul className={styles.no}>
                              <li style={{display:"inline-block", width: '95%', height: '100%', borderTop: '1px solid rgba(128, 128, 128, 0.47)', paddingTop: '20px', borderBottom: '1px solid #8080802e', paddingBottom: '20px'}}>
                                <Box className={styles.addressEle} style={{width: '20%', fontWeight: "bold"}}>{language.rankedNo}</Box>
                                <Box className={styles.addressEle} style={{width: '50%', fontWeight: "bold"}}>{language.rankedAddress}</Box>
                                <Box className={styles.addressEle} style={{width: '30%', fontWeight: "bold"}}>{language.rankedAmountPledge} {data.i < data.current ? ` / ${language.rankedAmountReward}` : ''}</Box>
                              </li>
                              {
                                ranked.list.map((item: any, idx) =>
                                    item.address
                                        ? <li className={styles.middleLine} key={'ranked_list_' + idx}  style={{ display:"inline-block",width:'95%'}}>
                                          <Box className={styles.addressEle} style={{width: '20%'}}>
                                            {
                                              idx < 3
                                                  ?
                                                  <Avatar style={{margin: 'auto'}} src={rankedSortIcon[idx]} />
                                                  :
                                                  <Box style={{fontWeight: 'bold', fontSize: '18px'}}>{idx + 1}</Box>
                                            }

                                          </Box>
                                          <Box className={(account + '').toUpperCase() == item.address.toUpperCase() ? styles.isMine : styles.addressEle} style={{width: '50%'}}>{os.isMobile? ellipsis(item.address, 15) : item.address}</Box>
                                          <Box className={(account + '').toUpperCase() == item.address.toUpperCase() ? styles.isMine : styles.addressEle} style={{width: '30%'}}>{item.amount} {data.i < data.current ? ` / ${item.reward}` : ''}</Box>
                                        </li>
                                        : null
                                )
                              }

                              {/*<li className={styles.middleLine}>*/}
                              {/*  <Box className={styles.addressEle} style={{width: '20%'}}>*/}
                              {/*    <Avatar style={{margin: 'auto'}} src={rankedSortIcon[0]} />*/}
                              {/*  </Box>*/}
                              {/*  <Box className={styles.addressEle} style={{width: '50%'}}>0xFb01b8fAf4A5f80dbfd8AD0345B9c3EdCDf60007</Box>*/}
                              {/*  <Box className={styles.addressEle} style={{width: '30%'}}>120</Box>*/}
                              {/*</li>*/}
                              {/*<li className={styles.middleLine}>*/}
                              {/*  <Box className={styles.addressEle} style={{width: '20%'}}>*/}
                              {/*    <Avatar style={{margin: 'auto'}} src={rankedSortIcon[1]} />*/}
                              {/*  </Box>*/}
                              {/*  <Box className={styles.addressEle} style={{width: '50%'}}>0xFb01b8fAf4A5f80dbfd8AD0345B9c3EdCDf60007</Box>*/}
                              {/*  <Box className={styles.addressEle} style={{width: '30%'}}>120</Box>*/}
                              {/*</li>*/}
                              {/*<li className={styles.middleLine} style={{ borderBottom: '1px solid rgba(128, 128, 128, 0.47)', paddingBottom: '20px', backgroundColor: '#0070ff0f', fontSize: '18px', fontWeight:'bold', color: "#2b8ce2"}}>*/}
                              {/*  <Box className={styles.addressEle} style={{width: '20%'}}>*/}
                              {/*    <Avatar style={{margin: 'auto'}} src={rankedSortIcon[2]} />*/}
                              {/*  </Box>*/}
                              {/*  <Box className={styles.addressEle} style={{width: '50%'}}>0xFb01b8fAf4A5f80dbfd8AD0345B9c3EdCDf60007</Box>*/}
                              {/*  <Box className={styles.addressEle} style={{width: '30%'}}>120</Box>*/}
                              {/*</li>*/}
                            </ul>
                          </Box>
                    }
                  </Box>
          )}
        </Box>
      </Container>
  );
}

export default Page;
